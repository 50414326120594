<div class="modal"
     style="display: block;"
     tabindex="-1"
     role="dialog"
     aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog quotation wide-dialog"
       role="document">
    <div class="modal-body">
      <span aria-hidden="true"
            (click)="onClose()"
            class="close">&times;</span>
      <div class="table-wrap">
          <div class="table-header">
              <div class="cell1">顧客番号</div>
              <div class="cell2">顧客名</div>
              <div class="cell3">フリガナ</div>
              <div class="cell4">住所</div>
              <div class="cell5">電話番号</div>
              <div class="cell6">携帯番号</div>
              <div class="cell7">拠点</div>
              <div class="cell8">担当者</div>
              <div class="cell9">商談区分</div>
            </div>
            <table class="table">
                <tbody>
            <tr *ngFor="let data of listvalue; let i = index"
                class="row"
                (click)="setClickedRow(i,data)"
                (dblclick)="onDbclickTable(data)"
                [class.active]="i == selectedRow"
                [class.deActive]="selectedRow !== i">
              <td class="cell1">{{data.customerKanriNo}}</td>
              <td class="cell2 align-l">{{data.customerNm}}
                <!-- <td class="tooltips">
                  <span class="subTips">
                    {{data.customerNm}}
                    <span class="tooltiptext" *ngIf="data.customerNm.length>18">{{data.customerNm}}</span>
                  </span>
                </td> -->
              </td>
              <td class="cell3 align-l">{{data.customerNmFn}}</td>
              <td class="cell4 align-l">
                {{data.zipNo1}} {{data.zipNo2}} {{data.address}} {{data.address1}} {{data.address2}}
              </td>
              <td class="cell5">{{data.telNumber}}</td>
              <td class="cell6">{{data.mobileNumber}}</td>
              <td class="cell7">{{data.kyotenNm}}</td>
              <td class="cell8">{{data.staffNm}}</td>
              <td class="cell9">{{data.shoudanKbn}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="paginator">
        <app-paginators #paginator [configOption]="sortTable"
                        (SortBack)="lazyLoadePage($event)"></app-paginators>
      </div>

      <div class="option">
        <div class="box1">
          <div>
            <p>商談区分</p>
            <div>
              <!-- <select class="width-select" [(ngModel)]="shodanKbn"> -->
              <!-- <select class="width-select" (change)="onShodanKbn($event)" [(ngModel)]="shodanKbn">
                <option [ngValue]="1"> 見込顧客 </option>
                <option [ngValue]="2"> 納車顧客 </option>
              </select> -->
              <select class="width-select"
                      [(ngModel)]="shodanKbn"
                      (change)="onShodanKbn($event)">
                <option></option>
                <option *ngFor="let kbn of shodanKbnVal"
                        [ngValue]="kbn"> {{kbn.kbnNm}} </option>
              </select>
            </div>
          </div>
          <div>
            <p>電話番号</p>
            <div>
              <input Select="true"
                     type="tel"
                     class="width-third mr4 ime_d"
                     [OnlyNumber]="true"
                     [(ngModel)]="telNumber1"
                     maxlength="5"
                     id="telNumber1"
                     (keydown.enter)="focus('telNumber2')">
              <input Select="true"
                     type="tel"
                     class="width-third ime_d"
                     [OnlyNumber]="true"
                     [(ngModel)]="telNumber2"
                     maxlength="5"
                     id="telNumber2"
                     (keydown.enter)="focus('telNumber3')">
              <input Select="true"
                     type="tel"
                     class="width-third ime_d"
                     [OnlyNumber]="true"
                     [(ngModel)]="telNumber3"
                     maxlength="5"
                     id="telNumber3"
                     (keydown.enter)="focus('mobileNumber1')">
            </div>
          </div>
          <div>
            <p>ランク1</p>
            <div>
              <input Select="true"
                     type="text"
                     class="width-input"
                     [(ngModel)]="showstrRank1" />
              <!--value="{{showstrRank1}}" -->
              <button class="search-btn"
                      (click)="openrank(1,1)">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>

          </div>
          <div>
            <p>分類1</p>
            <div>
              <input Select="true"
                     type="text"
                     class="width-input"
                     [(ngModel)]="showstr3Rank1" />
              <!--value="{{showstr3Rank1}}" -->
              <button class="search-btn"
                      (click)="openrank(3,1)">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
          <div>
            <p>PCメール</p>
            <div>
              <input Select="true"
                     type="tel"
                     class="width-input ime_d"
                     [(ngModel)]="mailPc"
                     id="mailPc"
                     (keydown.enter)="focus('mailMobile')">
            </div>
          </div>
        </div>
        <div class="box2">
          <div>
            <p>法人区分</p>
            <div>
              <select class="width-select"
                      [(ngModel)]="houjinKbn"
                      [disabled]="customersearchstatus"
                      (change)="oncorporate($event)">
                <option [ngValue]="0"> </option>
                <option [ngValue]="1"> 個人 </option>
                <option [ngValue]="2"> 法人 </option>
              </select>

            </div>
          </div>
          <div>
            <p>携帯番号</p>
            <div>
              <input Select="true"
                     type="tel"
                     class="width-third ime_d"
                     [(ngModel)]="mobileNumber1"
                     [OnlyNumber]="true"
                     maxlength="5"
                     id="mobileNumber1"
                     (keydown.enter)="focus('mobileNumber2')">
              <input Select="true"
                     type="tel"
                     class="width-third ime_d"
                     [OnlyNumber]="true"
                     [(ngModel)]="mobileNumber2"
                     maxlength="5"
                     id="mobileNumber2"
                     (keydown.enter)="focus('mobileNumber3')">
              <input Select="true"
                     type="tel"
                     class="width-third ime_d"
                     [OnlyNumber]="true"
                     [(ngModel)]="mobileNumber3"
                     maxlength="5"
                     id="mobileNumber3"
                     (keydown.enter)="focus('mailPc')">
            </div>
          </div>
          <div>
            <p class="center">～</p>
            <div>
              <input Select="true"
                     type="text"
                     class="width-input"
                     [(ngModel)]="showstrRank2" />
              <!--value="{{showstrRank2}}"-->
              <button class="search-btn"
                      (click)="openrank(1,2)">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
          <div>
            <p class="center">～</p>
            <div>
              <input Select="true"
                     type="text"
                     class="width-input"
                     [(ngModel)]="showstr3Rank2" />
              <!--value="{{showstr3Rank2}}"-->
              <button class="search-btn"
                      (click)="openrank(3,2)">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
          <div>
            <p>携帯メール</p>
            <div>
              <input Select="true"
                     type="tel"
                     class="width-input ime_d"
                     [(ngModel)]="mailMobile"
                     id="mailMobile"
                     (keydown.enter)="focus('customerNo')">
            </div>
          </div>
        </div>
        <div class="box3">
          <div>
            <p *ngIf="houjinKbn!=2">顧客名</p><p *ngIf="houjinKbn==2">法人名</p>
            <div>
              <input Select="true"
                     *ngIf="houjinKbn ==1 || houjinKbn==0"
                     type="text"
                     class="width-half ime_a"
                     (keydown.enter)="focus('kojinNmKjFirst')"
                     [(ngModel)]="kojinNmKjLast">
              <input Select="true"
                     *ngIf="houjinKbn ==1 || houjinKbn==0"
                     type="text"
                     class="width-half ime_a"
                     [(ngModel)]="kojinNmKjFirst"
                     id="kojinNmKjFirst"
                     (keydown.enter)="focus('kojinNmFnLast')">
              <input Select="true"
                     *ngIf="houjinKbn ==2"
                     type="text"
                     class="width-input ime_a"
                     [(ngModel)]="houjinNmKj"
                     (keydown.enter)="focus('houjinNmFn')">
              <!--[(ngModel)]="houjinNmKj"-->
            </div>
          </div>
          <div>
            <p>拠点</p>
            <div>
              <input Select="true"
                     type="text"
                     [(ngModel)]="kyotenNm"
                     maxlength="5"
                     class="width-input"
                     readonly/>
              <button class="search-btn"
                      (click)="getKyoten()">
                <i class="glyphicon glyphicon-search"></i>
              </button>
              <!--              
              <select class="width-select" [(ngModel)]="selectKyotens" (change)="onKyotyens($event)">
                <option *ngFor="let data of kyotens;let j=index;" [ngValue]="data">{{data.kyotenNm}}</option>
              </select> -->
            </div>
          </div>
          <div>
            <p>ランク2</p>
            <div>
              <input Select="true"
                     type="text"
                     class="width-input"
                     [(ngModel)]="showstr2Rank1" />
              <!--value="{{showstr2Rank1}}"-->
              <button class="search-btn"
                      (click)="openrank(2,1)">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
          <div>
            <p>分類2</p>
            <div>
              <input Select="true"
                     type="text"
                     class="width-input"
                     [(ngModel)]="showstr4Rank1" />
              <!--value="{{showstr4Rank1}}"-->
              <button class="search-btn"
                      (click)="openrank(4,1)">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
          <div>
            <p>顧客番号</p>
            <div>
              <input Select="true"
                     type="tel"
                     class="width-input ime_d"
                     [OnlyNumber]="true"
                     [(ngModel)]="customerNo"
                     id="customerNo">
            </div>
          </div>
        </div>
        <div class="box4">
          <div>
            <p>フリガナ</p>
            <div>
              <input Select="true"
                     *ngIf="houjinKbn ==1 || houjinKbn==0"
                     type="text"
                     class="width-half"
                     [(ngModel)]="kojinNmFnLast"
                     id="kojinNmFnLast"
                     (keydown.enter)="focus('kojinNmFnFirst')">
              <input Select="true"
                     *ngIf="houjinKbn ==1 || houjinKbn==0"
                     type="text"
                     class="width-half"
                     [(ngModel)]="kojinNmFnFirst"
                     id="kojinNmFnFirst"
                     (keydown.enter)="focus('telNumber1')">
              <input Select="true"
                     *ngIf="houjinKbn ==2 "
                     type="text"
                     class="width-input"
                     [(ngModel)]="houjinNmFn"
                     id="houjinNmFn"
                     (keydown.enter)="focus('telNumber1')">
            </div>
          </div>
          <div>
            <p>担当者</p>
            <div>
              <input Select="true"
                     type="text"
                     class="width-input"
                     [(ngModel)]="staffNm"
                     readonly/>
              <button class="search-btn"
                      (click)="displayTantou()">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
          <div>
            <p class="center">～</p>
            <div>
              <input Select="true"
                     type="text"
                     class="width-input"
                     [(ngModel)]="showstr2Rank2" />
              <!--value="{{showstr2Rank2}}"-->
              <button class="search-btn"
                      (click)="openrank(2,2)">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
          <div>
            <p class="center">～</p>
            <div>
              <input Select="true"
                     type="text"
                     class="width-input"
                     [(ngModel)]="showstr4Rank2" />
              <!--value="{{showstr4Rank2}}"-->
              <button class="search-btn"
                      (click)="openrank(4,2)">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="modal-footer btn-foot"
         style="justify-content: center !important;">
      <div>
        <a href="javascript:void(0);"
           class="btn"
           (click)="onClose()">閉じる</a>
        <a href="javascript:void(0);"
           class="btn btn_pink"
           *ngIf="dataenterkey"
           (click)="onConfirm()">選択</a>
        <a href="javascript:void(0);" 
           *ngIf="!dataenterkey" 
           class="btn notactive">選択</a>
        <a href="javascript:void(0);"
           class="btn btn_blue"
           (click)="onsearch()">検索</a>

      </div>
    </div>
  </div>
</div>


<!-- <p-dialog header="rank" [(visible)]="display" [width]="300" [height]="550" (onHide)="oncloseDialog($event)" modal="modal">
  <p-dataTable [value]="datalist" selectionMode="single" (onRowSelect)="onRowSelect($event)">
    <p-column *ngIf="datalist" field="rankNm" header="rankNm"></p-column>
    <table *ngIf="!datalist">
      <thead>rankNm</thead>
    </table>
  </p-dataTable>
</p-dialog> -->

<p-dialog header="ランク一覧"
          class="rank"
          [(visible)]="display"
          [width]="300"
          [height]="550"
          (onHide)="oncloseDialog($event)"
          modal="modal">
  <table class="show-rank">
    <tr *ngFor="let data of datalist; let i = index"
        (click)="onRowSelect(datalist[i])">
      <th class="show-rank-2">
        {{data.rankNm}}
      </th>
    </tr>
  </table>
</p-dialog>


<p-dialog header="販売担当"
          [(visible)]="tantoudisplay"
          modal="modal"
          width="350"
          (onHide)="oncloseDialog($event)">
  <div class="select-usedcar"
       *ngIf="tantoudisplay">
    <div class="box-type">
      <div class="list-type">
        <div *ngFor="let tandata of tantous; let i = index"
             (click)="tantouSelected(tandata, $event);checkedTantouSelected(true,i)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok"
               *ngIf="tantous[i].staffstat"></i>
            <span class="m-l-5">{{tandata.staffNm}}</span>
          </label>
        </div>
      </div>
      <div class="list-type"
           *ngIf="!tantous">
        <label class="type-check">
          <!-- <span>Data no found.</span> -->
        </label>
      </div>
    </div>
  </div>
  <!-- <p-dataTable [value]="tantous" selectionMode="single" (onRowSelect)="checkedTantouSelected($event)">
    <p-column field="staffNm" header="staffNm"></p-column>
  </p-dataTable> -->
</p-dialog>


<p-dialog header="販売拠点"
          [(visible)]="kyotendisplay"
          modal="modal"
          width="350"
          (onHide)="oncloseDialog($event)">
  <div class="select-usedcar"
       *ngIf="kyotendisplay">
    <!-- list type car -->
    <div class="box-type">
      <div class="list-type">
        <div *ngFor="let kyodata of kyotens; let i = index"
             (click)="kyotenSelected(kyodata, $event);checkedKyotenSelected(true,i)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok"
               *ngIf="kyotens[i].kyostat"></i>
            <span class="m-l-5">{{kyodata.kyotenNm}}</span>
          </label>
        </div>
      </div>
      <div class="list-type"
           *ngIf="!kyotens">
        <label class="type-check">
          <span>Data no found.</span>
        </label>
      </div>
    </div>
  </div>
</p-dialog>
