<div class="setting-dialog popup-dialog shadow-dialog ">
  <div class="close-container">
    <button type="button"
            class="close"
            (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="menu-item button"
       *ngIf="checkMenuAutho('HopMaster')"
       id="HopMaster"
       (click)="Submit('buppin_master');">販売店オプション</div>
  <div *ngIf="!checkMenuAutho('HopMaster')"
       class="menu-item button2">担当権限なし</div>

  <div class="menu-item button"
       *ngIf="checkMenuAutho('ChannelMaster') && visibleSetting.channelVisible"
       id="ChannelMaster"
       (click)="Submit('channel_master');">チャネル</div>
  <div *ngIf="!checkMenuAutho('ChannelMaster') && visibleSetting.channelVisible"
       class="menu-item button2">担当権限なし</div>

  <div class="menu-item button"
       *ngIf="checkMenuAutho('RemarkMaster')"
       id="RemarkMaster"
       (click)="Submit('bikou_master');">備考</div>
  <div *ngIf="!checkMenuAutho('RemarkMaster')"
       class="menu-item button2">担当権限なし</div>

  <!-- <div class="menu-item button" (click)="Submit('finance_master');">金融機関</div>
  <div class="menu-item button" (click)="Submit('mail_teikeibun_master');">メール定型文</div> -->
  <div class="menu-item button"
       *ngIf="checkMenuAutho('LoanMaster')"
       id="LoanMaster"
       (click)="Submit('bunkatsu_master');">分割払早見表</div>
  <div *ngIf="!checkMenuAutho('LoanMaster')"
       class="menu-item button2">担当権限なし</div>

  <!-- <div class="menu-item button" (click)="Submit('shomei_master');">署名</div> -->

  <div class="menu-item button"
       *ngIf="checkMenuAutho('GyoushaMaster') && visibleSetting.gyoushaVisible"
       id="GyoushaMaster"
       (click)="Submit('gyousha_master');">業者</div>
  <div *ngIf="!checkMenuAutho('GyoushaMaster') && visibleSetting.gyoushaVisible"
       class="menu-item button2">担当権限なし</div>

  <div class="menu-item button"
       *ngIf="checkMenuAutho('KashuuhiMaster') && visibleSetting.kashuuhiVisible"
       id="KashuuhiMaster"
       (click)="Submit('kashuuhi_master');">加修費</div>
  <div *ngIf="!checkMenuAutho('KashuuhiMaster') && visibleSetting.kashuuhiVisible"
       class="menu-item button2">担当権限なし</div>

  <div class="menu-item button"
       *ngIf="checkMenuAutho('InsuranceCompanyMaster')"
       id="InsuranceCompanyMaster"
       (click)="Submit('hoken_gaisha_master');">保険会社</div>
  <div *ngIf="!checkMenuAutho('InsuranceCompanyMaster')"
       class="menu-item button2">担当権限なし</div>

  <div class="menu-item button"
       *ngIf="checkMenuAutho('StaffMaster')"
       id="StaffMaster"
       (click)="Submit('tantousha_master');">担当者</div>
  <div *ngIf="!checkMenuAutho('StaffMaster')"
       class="menu-item button2">担当権限なし</div>

  <!-- <div class="menu-item button" (click)="Submit('menu_kengen_master');">メニュー担当権限</div> -->
  <div class="menu-item button"
       *ngIf="checkMenuAutho('CreditCompanyMaster')"
       id="CreditCompanyMaster"
       (click)="Submit('shinpan_master');">信販会社名称</div>
  <div *ngIf="!checkMenuAutho('CreditCompanyMaster')"
       class="menu-item button2">担当権限なし</div>

  <!-- <div class="menu-item button" (click)="Submit('shaken_tenken_hiyou_master');">車検点検費用</div> -->

  <div class="menu-item button"
       *ngIf="checkMenuAutho('CompanyPrintInfoMaster')"
       id="CompanyPrintInfoMaster"
       (click)="Submit('company_stamp_master');">印刷用会社</div>
  <div *ngIf="!checkMenuAutho('CompanyPrintInfoMaster')"
       class="menu-item button2">担当権限なし</div>

  <!-- <div class="menu-item button" (click)="Submit('shohiyou_kihon_master');">諸費用基本設定</div> -->
  <div class="menu-item button"
       *ngIf="checkMenuAutho('ShohiyouKoumokuMaster')"
       id="ShohiyouKoumokuMaster"
       (click)="Submit('shohiyou_master1');">諸費用項目</div>
  <div *ngIf="!checkMenuAutho('ShohiyouKoumokuMaster')"
       class="menu-item button2">担当権限なし</div>

  <div class="menu-item button"
       *ngIf="checkMenuAutho('HanbaiShohiyouMaster')"
       id="HanbaiShohiyouMaster"
       (click)="Submit('shohiyou_master2');">販売諸費用</div>
  <div *ngIf="!checkMenuAutho('HanbaiShohiyouMaster')"
       class="menu-item button2">担当権限なし</div>

  <!-- <div class="menu-item button" (click)="Submit('mail_setting_master');">メール送信元</div> -->
  <div class="menu-item button"
       *ngIf="checkMenuAutho('NyuukoBunruiMaster') && visibleSetting.nyuukoBunruiVisible"
       id="NyuukoBunruiMaster"
       (click)="Submit('nyuuko_bunrui');">入庫分類</div>
  <div *ngIf="!checkMenuAutho('NyuukoBunruiMaster') && visibleSetting.nyuukoBunruiVisible"
       class="menu-item button2">担当権限なし</div>

  <div class="menu-item button"
       *ngIf="checkMenuAutho('EnqueteMaster') && visibleSetting.enqueteVisible"
       id="EnqueteMaster"
       (click)="Submit('enquete_master');">アンケート</div>
  <div *ngIf="!checkMenuAutho('EnqueteMaster') && visibleSetting.enqueteVisible"
       class="menu-item button2">担当権限なし</div>


  <div class="menu-item button"
       *ngIf="checkMenuAutho('RankMaster') && visibleSetting.rankVisible"
       id="RankMaster"
       (click)="Submit('rank_master');">ランク</div>
  <div *ngIf="!checkMenuAutho('RankMaster') && visibleSetting.rankVisible"
       class="menu-item button2">担当権限なし</div>

  <div class="menu-item button"
       *ngIf="checkMenuAutho('MakerKouchinMaster')"
       id="MakerKouchinMaster"
       (click)="Submit('maker_kouchin_master');">メーカー工賃</div>
  <div *ngIf="!checkMenuAutho('MakerKouchinMaster')"
       class="menu-item button2">担当権限なし</div>

  <div class="menu-item button"
       *ngIf="checkMenuAutho('MenuKengenMaster')"
       id="MenuKengenMaster"
       (click)="Submit('menu_kengen_master');">メニュー担当権限</div>
  <div *ngIf="!checkMenuAutho('MenuKengenMaster')"
       class="menu-item button2">担当権限なし</div>

  <div class="menu-item button"
       (click)="onShowNews()"
       *ngIf="checkLoginType">新着情報マスタ</div>

  <div class="menu-item button"
       *ngIf="checkMenuAutho('PriceAdjustmentMaster') && visibleSetting.tentouKakakuChouseiVisible"
       id="PriceAdjustmentMaster"
       (click)="Submit('price_adjustment_master');">店頭価格調整</div>
  <div *ngIf="!checkMenuAutho('PriceAdjustmentMaster') && visibleSetting.tentouKakakuChouseiVisible"
       class="menu-item button2">担当権限なし</div>
  <!--
  <div *ngIf="checkMenuAutho('SalePlanEdit')" class="menu-item button" (click)="openOsusumeSetting()">おすすめプラン設定</div>
  <div *ngIf="!checkMenuAutho('SalePlanEdit')" class="menu-item button2">おすすめプラン設定</div>
  -->
</div>
<app-company-form #form></app-company-form>
