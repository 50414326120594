<div class="modal" style="display: block;" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog purchase wide-dialog" role="document">
    <div class="modal-body">
      <span aria-hidden="true" (click)="onClose()" class="close">&times;</span>
      <div class="table-wrap">
        <div class="table-header">
          <div class="cell1">
            <span>注文日</span>
          </div>
          <div class="cell2">
            <span>注文番号</span>
          </div>
          <div class="cell3">
            <span>管理番号</span>
          </div>
          <div class="cell4">
            <span>新車区分</span>
          </div>
          <div class="cell5">
            <span>販売拠点</span>
          </div>
          <div class="cell6">
            <span>販売担当</span>
          </div>
          <div class="cell7">
            <span>販売先(漢字)</span>
          </div>
          <div class="cell8">
            <span>販売先(フリガナ)</span>
          </div>
          <div class="cell9">
            <span>車名</span>
          </div>
          <div class="cell10">
            <span>注文区分</span>
          </div>
        </div>
        <table class="table">
          <tbody>
            <tr *ngFor="let data of listOrder; let i = index" class="myrow" (click)="setClickedRow(i,data)" (dblclick)="onDbclickTable(data)"
              [class.active]="i == selectedRow" [class.deActive]="selectedRow !== i">
              <td class="cell1" align="center">{{data.chumonYmd | japDate}}</td>
              <td class="cell2" align="center">{{data.chumonNo}}</td>
              <td class="cell3" align="center">{{data.kanriNo}}</td>
              <td class="cell4" align="center">{{data.sharyouKbn}}</td>
              <td class="cell5" align="center">{{data.hanbaiKyotenNm}}</td>
              <td class="cell6" align="center">{{data.hanbaiTantou}}</td>
              <td class="cell7" align="center">{{data.customerNm}}</td>
              <td class="cell8" align="center">{{data.customerNmKn}}</td>
              <td class="cell9" align="center">{{data.modelNm}}</td>
              <td class="cell10" align="center">{{data.shodanKbn}}</td>
            </tr>

          </tbody>
        </table>
      </div>
      <app-pagination class="search-pagination" [totalTab]="dataTotalSize" (page)="onPagination($event)"></app-pagination>
        <div class="l-option">
          <div>
            <p>新車区分</p>
            <div>
              <select class="width-select" [(ngModel)]="sharyouKbn">
                <option *ngFor="let data of dataSharyou" [ngValue]="data.kbn"> {{data.text}} </option>
              </select>
            </div>
          </div>
          <div>
            <p>注文番号</p>
            <div>
              <input appZeroFill OnlyNumber="true" Select="true" type="tel" class="width-input ime_d" maxlength="10" [(ngModel)]="chumonNo"
                (addZero)="onZeroFill1($event)" (focusout)="checkLanguage()" />
            </div>
          </div>
          <div>
            <p>販売拠点</p>
            <div>
              <!-- <input Select="true" type="text" [(ngModel)]="hanbaiKyotenCd" maxlength="5" class="width-input" /> -->
              <input Select="true" type="text" [(ngModel)]="hanbaiKyotenNm" maxlength="5" class="width-input" />
              <button class="search-btn" (click)="getKyoten()">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
          <div>
            <!-- <p>販売区分</p> -->
            <p>顧客区分</p>
            <div>
              <!-- <input Select="true" type="text" class="width-input" [(ngModel)]="kkkbn" maxlength="1" OnlyNumber="true" /> -->
              <select style="height: 26px;" class="width-select" [(ngModel)]="kkkbn">
                <option *ngFor="let item of customerKbn" [value]="item.kbn">{{item.text}}</option>
              </select>
            </div>
          </div>
          <div>
            <p>メーカー</p>
            <div>
              <input Select="true" type="text" class="width-input" value="{{carvalue.makerNm}}" readonly/>
              <button class="search-btn" (click)="onShowdialog()">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
            <!-- <div>
              <input Select="true" type="text" [(ngModel)]="makerCd" maxlength="5" class="width-input" />
              <button>
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div> -->
          </div>
        </div>
        <div class="r-option">
          <div>
            <p>注文日</p>
            <div>
              <!-- 148px -->
              <app-dropdowncalendar [styletextbox]="{'width' : '144px'}" (onselectDate)="onselectDate($event)" (onDatevalue)="onDatevalue($event)"
              (onfocusouts)="onDatevalue($event)" [defaultDate]="'off'"></app-dropdowncalendar>
              <!-- <select>
					<option>H</option>
				</select>
          <div class="date" id="datepicker" data-date-format="yyyy-mm-dd">
            <input type="text" class="width-halfe2"/>
            <i class="input-group-addon glyphicon glyphicon-calendar button-calendar"></i>
          </div> -->

            </div>
          </div>
          <div>
            <p>管理番号</p>
            <div>
              <button class="search-btn" (click)="getZaikoShaten()">
                <i class="glyphicon glyphicon-search"></i>
              </button>
              <input Select="true" type="tel" class="width-short ime_d" OnlyNumber="true" [(ngModel)]="zaikoShatenCd" maxlength="5" readonly>
              <input appZeroFill OnlyNumber="true" Select="true" type="tel" class="width-half ime_d" [(ngModel)]="zaikoKanriCd" maxlength="10"
                (addZero)="onZeroFill2($event)" (focusout)="checkLanguage2()">
            </div>
          </div>
          <div>
            <p>販売担当</p>
            <div>
              <!-- <input Select="true" type="text" class="width-input" [(ngModel)]="hanbaiTantouCd" maxlength="5" [disabled]="!hanbaiKyotenCd"
              /> -->
              <input Select="true"
                     type="text"
                     class="width-input"
                     [(ngModel)]="hanbaiTantouNm"
                     maxlength="5" />
              <button class="search-btn" (click)="displayTantou()">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
          <div>
            <p>販売先漢字</p>
            <div>
              <input Select="true" type="text" class="width-input" [(ngModel)]="hanbaiDestinationKanji" />
              <div style="margin-left: 3px;" class="pay_box shitadori_btn">
                <a href="javascript:void(0);" class="shitadori_link_actv" (click)="onShowQuot2(1)">顧客検索</a>
              </div>
            </div>
          </div>
          <div>
            <p>車名</p>
            <div>
              <input Select="true" type="text" class="width-input" value="{{carvalue.modelNm}}" [disabled]="!carvalue.makerNm" readonly/>
              <button class="search-btn" (click)="showDialogModel()" [disabled]="!carvalue.makerNm">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
            <!-- <div>
              <input Select="true" type="text" class="width-input" [(ngModel)]="modelCd" maxlength="5" />
              <button>
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div> -->
          </div>
        </div>
        <div class="last-option">
          <div>
            <p>販売先フリガナ</p>
            <div>
              <input Select="true" type="text" class="width-input" [(ngModel)]="hanbaiDestinationFn" />
              <div style="margin-left: 3px;" class="pay_box shitadori_btn">
                <a href="javascript:void(0);" class="shitadori_link_actv" (click)="onShowQuot2(2)">顧客検索</a>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="modal-footer btn-foot" style="justify-content: center !important;">
      <div>
        <a href="javascript:void(0);" class="btn" (click)="onClose()">閉じる</a>
        <a href="javascript:void(0);" *ngIf="orderItemsdata" class="btn btn_pink" (click)="onConfirm()">選択</a>
        <a href="javascript:void(0);" *ngIf="!orderItemsdata" class="btn notactive">選択</a>

        <a href="javascript:void(0);" class="btn btn_blue" (click)="onsearch()">検索</a>
      </div>
    </div>
  </div>
</div>
<app-quotation-search-2 *ngIf="showQuot2" [showCustomerSearch]="showCustomerSearch" (close)="closeFilter()"></app-quotation-search-2>

<p-dialog header="メーカー" [(visible)]="display" modal="modal" width="350" (onHide)="oncloseDialog($event)">
  <!-- select usedcar -->
  <div class="select-usedcar" *ngIf="makerdisplay">

    <!-- <p class="title-select">メーカー一覧</p> -->
    <div class="title-table cursor-point" (click)="filterMakerList()">全体</div>
    <div class="title-table cursor-point" (click)="filterMakerList(11)">英数</div>
    <div class="table-show-text">
      <div class="divrow">
        <div class="divcell" (click)="filterMakerList(1)">あ</div>
        <div class="divcell" (click)="filterMakerList(2)">か</div>
        <div class="divcell" (click)="filterMakerList(3)">さ</div>
        <div class="divcell" (click)="filterMakerList(4)">た</div>
        <div class="divcell" (click)="filterMakerList(5)">な</div>
      </div>
      <div class="divrow">
        <div class="divcell" (click)="filterMakerList(6)">は</div>
        <div class="divcell" (click)="filterMakerList(7)">ま</div>
        <div class="divcell" (click)="filterMakerList(8)">や</div>
        <div class="divcell" (click)="filterMakerList(9)">ら</div>
        <div class="divcell" (click)="filterMakerList(10)">わ</div>
      </div>
    </div>
    <!-- list type car -->
    <div class="box-type">
      <div class="list-type">
        <!-- <div (click)="onClearSelectedMaker(!clearCheckMaker)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="clearCheckMaker"></i>
            <span class="m-l-5">未選択</span>
          </label>
        </div> -->
        <div *ngFor="let maker of makerData; let i = index" (click)="makerSelected(maker, $event);checkedMakerSelected(true,i)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="makerData[i].stat"></i>
            <span class="m-l-5">{{maker.makerNm}}</span>
          </label>
        </div>
      </div>
      <div class="list-type" *ngIf="!makerData">
      </div>

    </div>

  </div>
</p-dialog>


<p-dialog header="車名" [(visible)]="modeldisplay" modal="modal" width="350" (onHide)="oncloseDialog($event)">
  <div class="select-usedcar" *ngIf="modeldisplay">
    <div class="title-table cursor-point" (click)="filterModelList()">全体</div>
    <div class="title-table cursor-point" (click)="filterModelList(11)">英数</div>
    <div class="table-show-text">
      <div class="divrow">
        <div class="divcell cursor-point" (click)="filterModelList(1)">あ</div>
        <div class="divcell cursor-point" (click)="filterModelList(2)">か</div>
        <div class="divcell cursor-point" (click)="filterModelList(3)">さ</div>
        <div class="divcell cursor-point" (click)="filterModelList(4)">た</div>
        <div class="divcell cursor-point" (click)="filterModelList(5)">な</div>
      </div>
      <div class="divrow">
        <div class="divcell cursor-point" (click)="filterModelList(6)">は</div>
        <div class="divcell cursor-point" (click)="filterModelList(7)">ま</div>
        <div class="divcell cursor-point" (click)="filterModelList(8)">や</div>
        <div class="divcell cursor-point" (click)="filterModelList(9)">ら</div>
        <div class="divcell cursor-point" (click)="filterModelList(10)">わ</div>
      </div>
    </div>

    <!-- list type car -->
    <div class="box-type" *ngIf="modeldisplay">
      <div class="list-type" *ngIf="modelData">
        <div *ngFor="let model of modelData; let i = index" (click)="modelSelected(model, $event);checkedModelSelected(true,i)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="modelData[i].stat"></i>
            <span class="m-l-5">{{model.modelNm}}</span>
          </label>
        </div>
      </div>
      <div class="list-type" *ngIf="!modelData">
      </div>
    </div>
  </div>
</p-dialog>



<div class="select-usedcar" *ngIf="gradesDispaly">
  <!-- list type car -->
  <div class="box-type" *ngIf="gradesDispaly">
    <div class="list-type" *ngIf="gradesdata">
      <div *ngFor="let grades of gradesdata; let i = index">
        <label class="type-check">
          <div class="icon-sqare-white"></div>
          <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="gradesdata[i].stat"></i>
          <span class="m-l-5">{{grades.gradeNm}}</span>
        </label>
      </div>
    </div>
    <div class="list-type" *ngIf="!modelData">
    </div>
  </div>
</div>

<p-dialog header="販売拠点" [(visible)]="kyotendisplay" modal="modal" width="350" (onHide)="oncloseDialog($event)">
  <div class="select-usedcar" *ngIf="kyotendisplay">
    <!-- list type car -->
    <div class="box-type">
      <div class="list-type">
        <div *ngFor="let kyodata of kyotens; let i = index" (click)="kyotenSelected(kyodata, $event);checkedKyotenSelected(true,i)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="kyotens[i].kyostat"></i>
            <span class="m-l-5">{{kyodata.kyotenNm}}</span>
          </label>
        </div>
      </div>
      <div class="list-type" *ngIf="!kyotens">
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog header="販売担当" [(visible)]="tantoudisplay" modal="modal" width="350" (onHide)="oncloseDialog($event)">
  <div class="select-usedcar" *ngIf="tantoudisplay">
    <!-- list type car -->
    <div class="box-type">
      <div class="list-type">
        <div *ngFor="let tandata of tantous; let i = index" (click)="tantouSelected(tandata, $event);checkedTantouSelected(true,i)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="tantous[i].staffstat"></i>
            <span class="m-l-5">{{tandata.staffNm}}</span>
          </label>
        </div>
      </div>
      <div class="list-type" *ngIf="!tantous">
      </div>
    </div>
  </div>
</p-dialog>
