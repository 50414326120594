<div class="modal"
     style="display: block;"
     tabindex="-1"
     role="dialog"
     aria-labelledby="exampleModalLabel"
     aria-hidden="true">

  <div class="modal-dialog"
       role="document">
    <div class="modal-header">
      新着情報マスタ
      <button class="btnclose float-right"
              (click)="onEditNews(true)">新規　新着情報を追加する</button>
    </div>
    <div class="modal-body">
      <div class="btn-head-wrap">
        <button class="btn-red" (click)="prevPage()">前の10件へ</button>
        <button class="btn-red" (click)="nextPage()">次の10件へ</button>
      </div>
      <div class="content-wrap">
        <div class="news-list" *ngFor="let data of newsList;let i=index" (click)="editNewsItems(data)">
          <p class="box-news-date" *ngIf="data.date">{{data.date | slice:0:4}}.{{data.date | slice:4:6}}.{{data.date | slice:6:8}}</p>
          <p class="box-news-title" *ngIf="data.title">[{{data.title}}</p><p class="last-frame">]</p>
          <p>{{data.body}}</p>
        </div>
        <p class="no-data" *ngIf="newsList?.length == 0">新着情報は、ありません</p>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btnclose"
              (click)="onClose()">閉じる</button>
    </div>
  </div>
</div>
<div *ngIf="editnews"
     class="modal"
     style="display: block;"
     tabindex="-1"
     role="dialog"
     aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog"
       role="document">

    <div class="modal-body">
      <div><span class="close" (click)="onEditNews(false)">×</span></div>
      <div>
        <span class="title-label">日付</span>
        <app-dropdowncalendar [defaultDate]="'off'"
                              [inputId]="'date'"
                              type="tel"
                              style="ime-mode: disabled"
                              (onDatevalue)="getDateValue($event)"
                              (onfocusouts)="getDateValue($event)"
                              [date]="date0"
                              [onicon]="true"
                              [formatdate]="'yy/mm/dd'"
                              [maxLength]="8"
                              (keydown.enter)="enterKey($event, 'title')"
                              [styletextbox]="{'width' : '125px'}">
        </app-dropdowncalendar>
      </div>
      <div>
        <span class="title-label">題名</span>
        <input type="text"
               class="ipt-title ime_a"
               id="title"
               [(ngModel)]="newsTitle"
               (keydown.enter)="enterKey($event, 'body')"
               [autofocus]="true"
               [wordstr]="100"
               [mxLen]="true"/>
      </div>
      <div>
        <span class="title-label">本文</span>
        <textarea class="ime_a" id="body" [(ngModel)]="newsDetail" [wordstr]="1600" [autofocus]="true" [mxLen]="true" rows="5" ></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <div class="float-left">
        <input type="radio" name="show" (click)="isShowNews(1)" [checked]="newsEnabled == 1" /> 表示
        <input type="radio" name="show" (click)="isShowNews(0)" [checked]="newsEnabled == 0"/> 非表示
      </div>
      <button id="saveBtn" class="btnclose" (click)="addNews()" *ngIf="!isUpdate">登録</button>
      <button id="saveBtn" class="btnclose" (click)="updateNews()" *ngIf="isUpdate">登録</button>
    </div>
  </div>
</div>
<app-dialog-maxlength *ngIf="displayError"
                      [inputname]="inputName"
                      [showEmpty]="true"
                      (close)="oncloseDialog()"></app-dialog-maxlength>

<!-- <app-edit-information *ngIf="editnews"
                      (editClose)="onEditNews($event)"></app-edit-information> -->
