<div class="modal" style="display: block;">
  <div class="modal-dialog dialog-setting">
    <div class="modal-header">おすすめプラン設定
      <span (click)="onClose()" class="close">&times;</span>
    </div>
    <div class="modal-body">
      <div class="row1">
        <app-osusume-dropdown (onChangePlanName)="onChangePlanName($event)"></app-osusume-dropdown>
        <div class="btn-container">
          <!-- <div class="btn" (click)="addPlanName()">
            <span class="fa fa-plus"></span>プラン名称を編集する</div> -->
          <div class="btn" (click)="editPlanName()">プラン名称を編集する</div>
        </div>
      </div>
      <div class="loader" *ngIf="isLoading"></div>
      <label class="lb-nodata" *ngIf="isNodata">登録済みのおすすめプランがありません。</label>
      <div class="tb" *ngIf="listPlan">
        <div class="row title">
          <div class="cell">No.</div>
          <div class="cell">見積番号</div>
          <div class="cell">車名</div>
          <div class="cell">車両本体価格</div>
          <div class="cell">値引き</div>
          <div class="cell">ローン月額</div>
          <div class="cell">リース月額</div>
          <div class="cell">表示</div>
          <div class="cell">処理</div>
        </div>
        <div [dragula]='"osusume"' [dragulaModel]="listPlan" class="row-group">
          <div class="row row-detail" *ngFor="let plan of listPlan;let i=index">
            <div class="cell no">{{i+1}}</div>
            <div class="cell mitsumoriCd">{{plan.mitsumoriNo}}</div>
            <div class="cell model">{{plan.modelNm}} {{plan.gradeNm}}</div>
            <div class="cell num">{{plan.carPriceZk|number}}</div>
            <div class="cell num">{{plan.nebikiZk|number}}</div>
            <div class="cell num">{{plan.loanMonthlyPayment|number}}</div>
            <div class="cell num">{{plan.leaseMonthlyPayment|number}}</div>
            <div class="cell center check">
              <label class="type-check" (click)="changeEnable(plan)">
                <div class="icon-sqare-white"></div>
                <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="IsEnable(plan.enabled)"></i>
              </label>
            </div>
            <div class="cell center cell-btn">
              <div class="btn btn-edit" (click)="createPlan(plan.mitsumoriNo)">
                <img src="../../../assets/newcar/newcar-detail/images/icon_edit.png">編集
              </div>
              <div class="btn btn-delete" (click)="removePlan(plan.id,false)">
                <span class="glyphicon glyphicon-trash"></span> 削除</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="text-footer">※表示順については、各行をドラッグ＆ドロップする事で入替えすることが出来ます。</div>
      <div class="btn btn-close" (click)="onClose()">閉じる</div>
    </div>
  </div>
</div>
<app-osusume-add *ngIf="showCategoryDialog" (close)="onCloseCategoryDialog()"></app-osusume-add>
<app-osusume-edit *ngIf="showEditDialog" (close)="onClseEditDialog()"></app-osusume-edit>
