<div class="modal"
     style="display: block;"
     tabindex="-1"
     role="dialog"
     aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog quotation-print"
       role="document">
    <div class="modal-body">
      <div class="area1">
        <div class="col">
          <div>
            <p>車検期限</p>
            <input type="text"
                   class="w180"
                   readonly
                   value="{{valuedata?.shakenKigen | japDate}}">
          </div>
          <div>
            <p>走行距離</p>
            <input class="w158"
                   type="text"
                   readonly
                   value="{{valuedata?.soukouKm | numcomma}}">km
          </div>
        </div>
        <div class="col">
          <div>
            <p>色</p>
            <input type="text"
                   class="w180"
                   readonly
                   value="{{valuedata?.colorNm}}">
          </div>
          <div>
            <p>長・幅・高</p>
            <input type="text"
                   readonly
                   class="w180"
                   value="{{valuedata?.carDimension}}">
          </div>
        </div>
        <div class="col">
          <div>
            <p>車台番号</p>
            <input type="text"
                   readonly
                   value="{{valuedata?.shadaiNo}}"
                   class="w180">
          </div>
          <div>
            <p>型式</p>
            <input type="text"
                   readonly
                   class="w180"
                   value="{{valuedata?.katashiki}}">
          </div>
        </div>
      </div>
      <div class="area2">
        <div class="col">
          <p>装備</p>
          <li *ngFor="let sb of soubi">
            <img src="assets/plan/usedcar/image/soubi/3123_{{sb.kbn}}.jpg"
                 alt="">
          </li>
        </div>
        <div class="col">
          <p>その他装備</p>
          <input type="text"
                 value="{{valuedata.priceCardEquipment}}"
                 class="w350">
        </div>
        <div class="col">
          <p>仕入メモ</p>
          <div *ngIf="zaikoshiirelist">
            <label *ngFor="let data of zaikoshiirelist;">{{data.memo}}
              <br>
            </label>
          </div>
          <div *ngIf="zaikoshiirelist.length ==0 ">
            <label></label>
          </div>
        </div>
      </div>
      <div class="area3">
        <div class="col head">
          <p class="head-area3">商談日</p>
          <p class="head-area3">社店</p>
          <p class="head-area3">商談担当</p>
        </div>
        <div class="col">
          <p>商談１</p>
          <input class="w130"
                 type="text"
                 value="{{valuedata?.shoudanDate1 | japDate}}">
          <input class="w130"
                 type="text"
                 value="{{valuedata?.shoudanShatenCd1}}">
          <input class="w130"
                 type="text"
                 value="{{valuedata?.shoudanNm1}}">
        </div>
        <div class="col">
          <p>商談２</p>
          <input class="w130"
                 type="text"
                 value="{{valuedata?.shoudanDate2 | japDate}}">
          <input class="w130"
                 type="text"
                 value="{{valuedata?.shoudanShatenCd2}}">
          <input class="w130"
                 type="text"
                 value="{{valuedata?.shoudanNm2}}">
        </div>
        <div class="col">
          <p>商談３</p>
          <input class="w130"
                 type="text"
                 value="{{valuedata?.shoudanDate3 | japDate}}">
          <input class="w130"
                 type="text"
                 value="{{valuedata?.shoudanShatenCd3}}">
          <input class="w130"
                 type="text"
                 value="{{valuedata?.shoudanNm3}}">
        </div>
      </div>


      <div class="modal-footer">
        <button class="footer-btn"
                (click)="onClose()">閉じる</button>
      </div>
    </div>
  </div>
