<div class="modal" style="display: block;" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog quotation wide-dialog" role="document">
    <div class="modal-body">
      <span aria-hidden="true" (click)="onClose()" class="close">&times;</span>
      <div class="table-wrap">
        <div class="table-header">
          <div class="cell1">
            見積日
          </div>
          <div class="cell2">
            見積番号
          </div>
          <div class="cell3">
            管理番号
          </div>
          <div class="cell4">
            車両番号
          </div>
          <div class="cell5">
            販売担当
          </div>
          <div class="cell6">
            顧客名
          </div>
          <div class="cell7">
            車名
          </div>
          <div class="cell8">
            商談区分
          </div>
          <div class="cell9">
            販売拠点
          </div>
        </div>
        <table class="table">

          <tbody *ngIf="listData">
            <tr *ngFor="let data of listData; let i = index" class="myrow" (click)="setClickedRow(i,data)" (dblclick)="onDbclickTable(data)"
              [class.active]="i == selectedRow" [class.deActive]="selectedRow !== i && data.isOrderCreated !== '1'" [class.orderCreated]="data.isOrderCreated === '1'">
              <td align="center" class="cell1">{{data.mitsumoriYmd | japDate}}</td>
              <td align="center" class="cell2">{{data.mitsumoriNo}}</td>
              <td align="center" class="cell3">{{data.kanriNo}}</td>
              <td align="center" class="cell4">{{data.sharyouKbn}}</td>
              <td align="center" class="cell5">{{data.hanbaiTantou}}</td>
              <td align="center" class="cell6">{{data.customerNm}}</td>
              <td align="center" class="cell7">{{data.modelNm}}</td>
              <td align="center" class="cell8">{{data.shodanKbn}}</td>
              <td align="center" class="cell9">{{data.hanbaiKyotenNm}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <app-pagination class="search-pagination" [totalTab]="dataTotalSize" (page)="onPagination($event)"></app-pagination>
        <div class="l-option">
          <div>
            <label class="l-opt-label">新車区分</label>
            <!-- <div> -->
            <select class="width-select" [(ngModel)]="sharyouKbn">
              <option *ngFor="let data of dataSharyou;" [ngValue]="data.kbn"> {{data.text}} </option>
            </select>
            <!-- </div> -->
          </div>
          <div>
            <label class="l-opt-label">見積番号</label>
            <!-- <div> -->
            <!-- (blur)="onzeroFill($event.target.value)" -->
            <!-- (focusout)="checkLanguage($event)" -->
            <input Select="true" type="tel" class="width-input ime_d" maxlength="10" [(ngModel)]="mitsumoriNovalue" [OnlyNumber]="true"
              appZeroFill (addZero)="onzeroFill1($event)" (focusout)="checkLanguage()" />
            <!-- </div> -->
          </div>
          <div>
            <label class="l-opt-label">販売拠点</label>
            <!-- <div> -->
            <!-- <input Select="true" type="text" class="width-input" maxlength="5" [(ngModel)]="hanbaikyitenCd" /> -->
            <input Select="true" type="text" [(ngModel)]="hanbaiKyotenNm" maxlength="5" class="width-input" readonly/>
            <button class="search-btn" (click)="getKyoten()">
              <i class="glyphicon glyphicon-search"></i>
            </button>
            <!-- </div> -->
          </div>
          <div>
            <label class="l-opt-label-l">販売先漢字</label>
            <!-- <div> -->
            <input Select="true" type="text" class="width-input" [(ngModel)]="hanbaiDestinationKanji" />
            <div class="pay_box shitadori_btn">
              <a href="javascript:void(0);" class="shitadori_link_actv" (click)="onShowQuot2(1)">顧客検索</a>
            </div>
            <!-- </div> -->
          </div>
          <div>
            <label class="l-opt-label">メーカー</label>
            <!-- <div> -->
            <input Select="true" type="text" class="width-input" [(ngModel)]="carvalue.makerNm" readonly/>
            <!--value="{{carvalue.makerNm}}"-->
            <button class="search-btn" (click)="onShowdialog()">
              <i class="glyphicon glyphicon-search"></i>
            </button>
            <!-- </div> -->
          </div>
        </div>
        <div class="r-option">
          <div>
            <label class="r-opt-label-f">見積日</label>
            <!-- <div> -->


            <app-dropdowncalendar (onfocusouts)="onDatevalue($event)" (onDatevalue)="onDatevalue($event)" (onselectDate)="onselectDate($event)" [styletextbox]="{'width' : '143px'}"
              [defaultDate]="'off'"></app-dropdowncalendar>
            <!-- <select>
					<option>H</option>
			        	</select>
              <div class="date" id="datepicker" data-date-format="yyyy-mm-dd">
                <input type="text" class="width-halfe2" />
                <i class="input-group-addon glyphicon glyphicon-calendar button-calendar"></i>
              </div> -->

            <!-- </div> -->
          </div>
          <div>
            <label class="r-opt-label">管理番号</label>
            <!-- <div> -->
            <button class="search-btn" (click)="getZaikoShaten()">
              <i class="glyphicon glyphicon-search"></i>
            </button>
            <input Select="true" type="tel" class="width-short ime_d" [(ngModel)]="zaikoShatenCd" [OnlyNumber]="true" maxlength="5" readonly>
            <input [OnlyNumber]="true" Select="true" type="tel" class="width-half ime_d" [(ngModel)]="zaikoKanriCd" maxlength="10" appZeroFill
              (addZero)="onzeroFill2($event)" (focusout)="checkLanguage2()">
            <!-- <input Select="true" type="text" class="width-short">
              <input type="text" class="width-half"> -->
            <!-- </div> -->
          </div>
          <div>
            <label class="r-opt-label">販売担当</label>
            <!-- <div> -->
            <!-- <input Select="true" type="text" class="width-input" maxlength="5" [(ngModel)]="hanbaiTantouCd" /> -->
            <input Select="true"
                   type="text"
                   class="width-input"
                   [(ngModel)]="hanbaiTantouNm"
                   maxlength="5"
                   readonly/>
            <button class="search-btn" (click)="displayTantou()">
              <i class="glyphicon glyphicon-search"></i>
            </button>
            <!-- </div> -->
          </div>
          <div>
            <label class="r-opt-label-l">販売先フリガナ</label>
            <!-- <div> -->
            <input Select="true" type="text" class="width-input" [(ngModel)]="hanbaiDestinationFn" />
            <div class="pay_box shitadori_btn">
              <a href="javascript:void(0);" class="shitadori_link_actv" (click)="onShowQuot2(2)">顧客検索</a>
            </div>
            <!-- </div> -->
          </div>
          <div>
            <label class="r-opt-label-s">車名</label>
            <!-- <div> -->
            <input Select="true" type="text" class="width-input" [(ngModel)]="carvalue.modelNm" [disabled]="!carvalue.makerNm" readonly/>
            <!--value="{{carvalue.modelNm}}"-->
            <button class="search-btn" (click)="showDialogModel()" [disabled]="!carvalue.makerNm">
              <i class="glyphicon glyphicon-search"></i>
            </button>
            <!-- </div> -->
          </div>
        </div>
    </div>
    <!-- listData.length !=0  -->
    <div class="modal-footer btn-foot" style="justify-content: center !important;">
        <p style="color: #ff0000">緑の行：注文作成済</p>
      <div>
        <a href="javascript:void(0);" class="btn" (click)="onClose()">閉じる</a>
        <a href="javascript:void(0);" *ngIf="selectdata" class="btn btn_pink" (click)="onConfirm()">選択</a>
        <a href="javascript:void(0);" *ngIf="!selectdata" class="btn notactive">選択</a>
        <a href="javascript:void(0);" class="btn btn_blue" (click)="onsearching()">検索</a>
      </div>
    </div>
  </div>
</div>
<app-quotation-search-2 *ngIf="showQuot2" [showCustomerSearch]="showCustomerSearch" (close)="closeFilter()"></app-quotation-search-2>

<p-dialog header="メーカー" [(visible)]="display" modal="modal" width="350" (onHide)="oncloseDialog($event)">
  <!-- select usedcar -->
  <div class="select-usedcar" *ngIf="makerdisplay">

    <!-- <p class="title-select">メーカー一覧</p> -->
    <div class="title-table cursor-point" (click)="filterMakerList()">全体</div>
    <div class="title-table cursor-point" (click)="filterMakerList(11)">英数</div>
    <div class="table-show-text">
      <div class="divrow">
        <div class="divcell" (click)="filterMakerList(1)">あ</div>
        <div class="divcell" (click)="filterMakerList(2)">か</div>
        <div class="divcell" (click)="filterMakerList(3)">さ</div>
        <div class="divcell" (click)="filterMakerList(4)">た</div>
        <div class="divcell" (click)="filterMakerList(5)">な</div>
      </div>
      <div class="divrow">
        <div class="divcell" (click)="filterMakerList(6)">は</div>
        <div class="divcell" (click)="filterMakerList(7)">ま</div>
        <div class="divcell" (click)="filterMakerList(8)">や</div>
        <div class="divcell" (click)="filterMakerList(9)">ら</div>
        <div class="divcell" (click)="filterMakerList(10)">わ</div>
      </div>
    </div>
    <!-- list type car -->
    <div class="box-type">
      <div class="list-type">
        <!-- <div (click)="onClearSelectedMaker(!clearCheckMaker)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="clearCheckMaker"></i>
            <span class="m-l-5">未選択</span>
          </label>
        </div> -->
        <div *ngFor="let maker of makerData; let i = index" (click)="makerSelected(maker, $event);checkedMakerSelected(true,i)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="makerData[i].stat"></i>
            <span class="m-l-5">{{maker.makerNm}}</span>
          </label>
        </div>
      </div>
      <div class="list-type" *ngIf="!makerData">
      </div>

    </div>

  </div>
</p-dialog>


<p-dialog header="車名" [(visible)]="modeldisplay" modal="modal" width="350" (onHide)="oncloseDialog($event)">

  <div class="select-usedcar" *ngIf="modeldisplay">
    <div class="title-table cursor-point" (click)="filterModelList()">全体</div>
    <div class="title-table cursor-point" (click)="filterModelList(11)">英数</div>
    <div class="table-show-text">
      <div class="divrow">
        <div class="divcell cursor-point" (click)="filterModelList(1)">あ</div>
        <div class="divcell cursor-point" (click)="filterModelList(2)">か</div>
        <div class="divcell cursor-point" (click)="filterModelList(3)">さ</div>
        <div class="divcell cursor-point" (click)="filterModelList(4)">た</div>
        <div class="divcell cursor-point" (click)="filterModelList(5)">な</div>
      </div>
      <div class="divrow">
        <div class="divcell cursor-point" (click)="filterModelList(6)">は</div>
        <div class="divcell cursor-point" (click)="filterModelList(7)">ま</div>
        <div class="divcell cursor-point" (click)="filterModelList(8)">や</div>
        <div class="divcell cursor-point" (click)="filterModelList(9)">ら</div>
        <div class="divcell cursor-point" (click)="filterModelList(10)">わ</div>
      </div>
    </div>

    <!-- list type car -->
    <div class="box-type" *ngIf="modeldisplay">
      <div class="list-type" *ngIf="modelData">
        <!-- <div (click)="onClearSelectedModel(!clearCheckModel)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="clearCheckModel"></i>
            <span class="m-l-5">未選択</span>
          </label>
        </div> -->
        <div *ngFor="let model of modelData; let i = index" (click)="modelSelected(model, $event);checkedModelSelected(true,i)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="modelData[i].stat"></i>
            <span class="m-l-5">{{model.modelNm}}</span>
          </label>
        </div>
      </div>
      <div class="list-type" *ngIf="!modelData">
      </div>
    </div>
  </div>
</p-dialog>

<div class="select-usedcar" *ngIf="gradesDispaly">
  <!-- list type car -->
  <div class="box-type" *ngIf="gradesDispaly">
    <div class="list-type" *ngIf="gradesdata">
      <div *ngFor="let grades of gradesdata; let i = index">
        <label class="type-check">
          <div class="icon-sqare-white"></div>
          <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="gradesdata[i].stat"></i>
          <span class="m-l-5">{{grades.gradeNm}}</span>
        </label>
      </div>
    </div>
    <div class="list-type" *ngIf="!modelData">
    </div>
  </div>
</div>
<!-- <p-footer>
        <button type="button" pButton icon="fa-check" (click)="oncloseDialog()" label="OK"></button> -->
<!-- <button type="button" pButton icon="fa-close" (click)="oncloseDialog()" label="OK"></button> -->
<!-- </p-footer> -->


<p-dialog header="販売拠点" [(visible)]="kyotendisplay" modal="modal" width="350" (onHide)="oncloseDialog($event)">
  <div class="select-usedcar" *ngIf="kyotendisplay">
    <!-- list type car -->
    <div class="box-type">
      <div class="list-type">
        <div *ngFor="let kyodata of kyotens; let i = index" (click)="kyotenSelected(kyodata, $event);checkedKyotenSelected(true,i)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="kyotens[i].kyostat"></i>
            <span class="m-l-5">{{kyodata.kyotenNm}}</span>
          </label>
        </div>
      </div>
      <div class="list-type" *ngIf="!kyotens">
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog header="販売担当" [(visible)]="tantoudisplay" modal="modal" width="350" (onHide)="oncloseDialog($event)">
  <div class="select-usedcar" *ngIf="tantoudisplay">
    <!-- list type car -->
    <div class="box-type">
      <div class="list-type">
        <div *ngFor="let tandata of tantous; let i = index" (click)="tantouSelected(tandata, $event);checkedTantouSelected(true,i)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="tantous[i].staffstat"></i>
            <span class="m-l-5">{{tandata.staffNm}}</span>
          </label>
        </div>
      </div>
      <div class="list-type" *ngIf="!tantous">
      </div>
    </div>
  </div>
</p-dialog>
