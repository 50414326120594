<div class="business-dialog popup-dialog shadow-dialog">
  <div class="close-container">
    <button type="button" class="close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="menu-container">
    <div class="col col1">
      <div class="head-item">仕入</div>
      <div class="menu-item">
        <div *ngIf="checkMenuAutho('StockEdit')"  class="button" (click)="Submit('stock_edit')">仕入登録</div>
        <div *ngIf="!checkMenuAutho('StockEdit')"  class="button-disabled">担当権限なし</div>
      </div>
      <div class="menu-item">
        <div *ngIf="checkMenuAutho('StockStateEdit')"  class="button" (click)="Submit('stock_state_edit')">車両状態表</div>
        <div *ngIf="!checkMenuAutho('StockStateEdit')"  class="button-disabled">担当権限なし</div>
      </div>
      <div class="menu-item">
        <div *ngIf="checkMenuAutho('StockImageEdit')"  class="button" (click)="Submit('stock_image_edit')">画像登録</div>
        <div *ngIf="!checkMenuAutho('StockImageEdit')"  class="button-disabled">担当権限なし</div>
      </div>
      <div class="menu-item">
        <div *ngIf="checkMenuAutho('StockGenkaEdit')"  class="button" (click)="Submit('genka_edit')">原価登録</div>
        <div *ngIf="!checkMenuAutho('StockGenkaEdit')"  class="button-disabled">担当権限なし</div>
      </div>
    </div>
    <div class="col col2">
      <div class="head-item">販売</div>
      <div class="menu-item">
        <div *ngIf="checkMenuAutho('SalePlanEdit')"  class="button" routerLink="/planmain" [queryParams]="{new: 1}">プラン作成</div>
        <div *ngIf="!checkMenuAutho('SalePlanEdit')"  class="button-disabled">担当権限なし</div>
      </div>
      <div class="menu-item">
        <div *ngIf="checkMenuAutho('SaleGyouhanEdit')"  class="button" (click)="Submit('sales_gyouhan_edit')">業販登録</div>
        <div *ngIf="!checkMenuAutho('SaleGyouhanEdit')"  class="button-disabled">担当権限なし</div>
      </div>
      <div class="menu-item">
        <div *ngIf="checkMenuAutho('SaleAuctionHanbaiEdit')"  class="button" (click)="Submit('sales_auction_edit')">AA販売登録</div>
        <div *ngIf="!checkMenuAutho('SaleAuctionHanbaiEdit')"  class="button-disabled">担当権限なし</div>
      </div>
      <div class="menu-item">
        <div *ngIf="checkMenuAutho('SaleKaitaiEdit')"  class="button" (click)="Submit('sales_kaitai_edit')">解体登録</div>
        <div *ngIf="!checkMenuAutho('SaleKaitaiEdit')"  class="button-disabled">担当権限なし</div>
      </div>
    </div>
    <div class="col col3">
      <div class="head-item">顧客</div>
      <div class="menu-item">
        <div *ngIf="checkMenuAutho('CustomerEdit')"  class="button" (click)="Submit('customer_edit')">顧客・車両登録</div>
        <div *ngIf="!checkMenuAutho('CustomerEdit')" class="button-disabled">担当権限なし</div>
      </div>
      <!-- <div class="menu-item"><div class="button" >メンテナンスパック</div></div> -->
    </div>
    <!-- <div class="col col4">
        <div class="head-item">整備</div>
        <div class="menu-item"><div class="button" >車検</div></div>
        <div class="menu-item"><div class="button" >点検</div></div>
        <div class="menu-item"><div class="button">メンテナンスパック</div></div>
      </div> -->
    <div class="col col5">
      <div class="head-item">勘定</div>
      <!-- <div class="menu-item"><div class="button" >売上管理</div></div> -->
      <div class="menu-item">
        <div *ngIf="checkMenuAutho('CalcArariEdit')"  class="button" (click)="Submit('kanjou_arari_edit')">粗利益登録（小売)</div>
        <div *ngIf="!checkMenuAutho('CalcArariEdit')"  class="button-disabled">担当権限なし</div>
      </div>
    </div>
    <div class="col col6">
      <div class="head-item">出力</div>
      <div class="menu-item">
        <div class="button" (click)="ToggleSubMenu('1')">仕入管理</div>
        <div class="submenu shadow-dialog" *ngIf="IsShow('1')">
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('StockListForCustomer')"  class="button" (click)="Submit('stock_list_customer')">在庫リスト(お客様用)</div>
            <div *ngIf="!checkMenuAutho('StockListForCustomer')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('StockListForPrivate')"  class="button" (click)="Submit('stock_list_office')">在庫リスト(社内用)</div>
            <div *ngIf="!checkMenuAutho('StockListForPrivate')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('KobutsuList')"  class="button" (click)="Submit('kobutsu_list')">古物リスト</div>
            <div *ngIf="!checkMenuAutho('KobutsuList')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="button-gray" (click)="ToggleSubMenu('1')">閉じる</div>
        </div>
      </div>
      <div class="menu-item">
        <div class="button" (click)="ToggleSubMenu('2')">販売関連</div>
        <div class="submenu shadow-dialog" *ngIf="IsShow('2')">
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('MitsumoriList')"  class="button" (click)="Submit('estimate_list')">見積リスト</div>
            <div *ngIf="!checkMenuAutho('MitsumoriList')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('ChumonList')"  class="button" (click)="Submit('order_list')">注文リスト</div>
            <div *ngIf="!checkMenuAutho('ChumonList')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('CanceledChumonList')"  class="button" (click)="Submit('order_cancel_list')">キャンセル注文リスト</div>
            <div *ngIf="!checkMenuAutho('CanceledChumonList')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('NoPhotoStockList')"  class="button" (click)="Submit('no_image_list')">画像未登録リスト</div>
            <div *ngIf="!checkMenuAutho('NoPhotoStockList')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="button-gray" (click)="ToggleSubMenu('2')">閉じる</div>
        </div>
      </div>
      <div class="menu-item">
        <div class="button" (click)="ToggleSubMenu('3')">顧客関連</div>
        <div class="submenu shadow-dialog" *ngIf="IsShow('3')">
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('CustomerList')" class="button" (click)="Submit('customer_list')">お客様リスト</div>
            <div *ngIf="!checkMenuAutho('CustomerList')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('CarList')"  class="button" (click)="Submit('customer_car_list')">車両リスト</div>
            <div *ngIf="!checkMenuAutho('CarList')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('AttackList')"  class="button" (click)="Submit('attack_list')">アタックリスト</div>
            <div *ngIf="!checkMenuAutho('AttackList')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('CustomerTackSeal')"  class="button" (click)="Submit('tack_seal_customer')">タックシール(顧客)</div>
            <div *ngIf="!checkMenuAutho('CustomerTackSeal')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('CarTackSeal')"  class="button" (click)="Submit('tack_seal_customer_car')">タックシール(車両)</div>
            <div *ngIf="!checkMenuAutho('CarTackSeal')" class="button-disabled">担当権限なし</div>
          </div>
          <div class="button-gray" (click)="ToggleSubMenu('3')">閉じる</div>
        </div>
      </div>
      <div class="menu-item">
        <div class="button" (click)="ToggleSubMenu('4')">粗利益関連</div>
        <div class="submenu shadow-dialog" *ngIf="IsShow('4')">
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('ArariekiListOfKouri')"  class="button" (click)="Submit('kanjou_arari_kouri_list')">粗利益リスト(小売)</div>
            <div *ngIf="!checkMenuAutho('ArariekiListOfKouri')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('SharyouekiListOfKouri')"  class="button" (click)="Submit('kanjou_sharyoueki_kouri_list')">車両益リスト(小売)</div>
            <div *ngIf="!checkMenuAutho('SharyouekiListOfKouri')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('ArariekiListOfOroshiuri')"  class="button" (click)="Submit('kanjou_arari_oroshi_list')">粗利益リスト(卸売)</div>
            <div *ngIf="!checkMenuAutho('ArariekiListOfOroshiuri')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="menu-item">
            <div *ngIf="checkMenuAutho('KashuuYosanKakuninList')"  class="button" (click)="Submit('kashuu_yosan_kakunin_list')">加修予算確認リスト</div>
            <div *ngIf="!checkMenuAutho('KashuuYosanKakuninList')"  class="button-disabled">担当権限なし</div>
          </div>
          <div class="button-gray" (click)="ToggleSubMenu('4')">閉じる</div>
        </div>
      </div>
    </div>
    <!--<div class="col col7">
      <div class="head-item">掲載</div>
       <div class="menu-item"><div class="button"(click)="Submit('')">販促・消耗品</div></div> 
    </div>-->
  </div>
</div>
<app-company-form #form></app-company-form>
