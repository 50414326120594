<div class="inline">
  <select [attr.id]="inputIdselect"
          [ngStyle]="styledropdown"
          class="selectClass"
          [(ngModel)]="modeDate"
          (change)="onChange()"
          (keydown.enter)="keyEnter()"
          (focus)="focus()"
          [disabled]="disabledinput">
    <option [ngValue]="i"
            *ngFor="let i of test;let j=index;"
            [selected]="j==indexselect">
      {{i.gengoMark1}}
    </option>
  </select>
  <app-calendar-date [(ngModel)]="date"
                     [locale]="js"
                     (onSelect)="onSelectDate($event)"
                     [dateFormat]="formatdate"
                     [kanjidate]="dataDate"
                     [onoffChanges]="false"
                     (onDataInput)="ontextbox($event)"
                     [hidden]="hiddenDate"
                     [monthNavigator]="true"
                     [showIcon]="onicon"
                     (onData)="onDate($event)"
                     (onmodedropdown)="onmodedropdown($event)"
                     [onmaxLength]="maxLength"
                     [inputStyle]="styletextbox"
                     (onsendEnter)="sendID()"
                     [yearNavigator]="true"
                     [inputId]="inputId"
                     [showOnFocus]="showOnFocusDate"
                     [readonlyInput]="readonly"
                     [ondateClick]="ondatelayout"
                     [focusDate]="focusDate"
                     (onFocusDate)="setFocusDate($event)"
                     (onBlur)="onBlur($event)"
                     [disabled]="disabledinput"
                     [autoformat]="autoformat"></app-calendar-date>

  <input Select="true"
         *ngIf="hiddenDate"
         class="textboxs"
         [attr.id]="inputhidden"
         (focusout)="onfocusout($event)"
         [ngStyle]="style"
         type="tel"
         style="ime-mode: disabled"
         [(ngModel)]="currentDate"
         [readonly]="readonlyhidden"
         [disabled]="disabledinput"
         (keypress)="onkeypress($event)"
         (keydown.enter)="skipInput($event)"
         (focus)="focus()">
  <!-- onupdateDate($event); -->
</div>
