<div class="modal" style="display: block;">
  <div class="modal-dialog dialog-edit">
      <div class="modal-header">
          <span (click)="onClose()" class="close">&times;</span>
        </div>
    <div class="modal-body">
      <div class="loader" *ngIf="isLoading"></div>
      <label class="lb-nodata" *ngIf="isNodata">登録済みのおすすめプランがありません。</label>
      <div class="tb" *ngIf="listCategory">
        <div class="row title">
          <div class="cell">No.</div>
          <div class="cell">おすすめプラン名称</div>
          <div class="cell">種別</div>
          <div class="cell">処理</div>
        </div>
        <div class="row" *ngFor="let category of listCategory;let i=index">
          <div class="cell no">{{i+1}}</div>
          <div class="cell">{{category}}</div>
          <div class="cell center">
            <select>
              <option>加盟店様登録</option>
            </select>
          </div>
          <div class="cell center">
            <div class="btn btn-edit" (click)="editCategory(i, category)">
              <img src="../../../assets/newcar/newcar-detail/images/icon_edit.png">編集
            </div>
            <!-- <div class="btn btn-delete" (click)="removeCategory(i)">
              <span class="glyphicon glyphicon-trash"></span> 削除</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="btn btn-close" (click)="onClose()">閉じる</div>
    </div>
  </div>
</div>
<app-osusume-add *ngIf="showCategoryDialog" [categoryNo]="categoryNo" [oldName]="categoryName" (close)="onCloseCategoryDialog()"></app-osusume-add>
