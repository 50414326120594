<form ngNoForm
      [formGroup]="companyForm"
      [action]="extremeWebUrl"
      method="POST"
      style="display: none;"
      #form>
  <input type="text"
         name="group_code"
         formControlName="group_code" />
  <input type="text"
         name="company_code"
         formControlName="company_code" />
  <!-- <input type="text" name="company_name" formControlName="company_name" /> -->
  <input type="text"
         name="kyoten_code"
         formControlName="kyoten_code" />
  <!-- <input type="text" name="kyoten_name" formControlName="kyoten_name" /> -->
  <input type="text"
         name="tantou_code"
         formControlName="tantou_code" />
  <!-- <input type="text" name="tantou_name" formControlName="tantou_name" /> -->
  <input type="text"
         name="target_id"
         formControlName="target_id" />
  <!-- <input type="text" name="auth_key" formControlName="auth_key" /> -->
  <!-- <input type="text" name="auth_date" formControlName="auth_date" /> -->
  <input type="text"
         name="ckj_auth_token"
         formControlName="ckj_auth_token" />
  <input type="text"
         name="url_return"
         formControlName="url_return" />
  <input type="text"
         name="url_toppage"
         formControlName="url_toppage" />
  <input type="text"
         name="main_site_root_url"
         formControlName="main_site_root_url" />
  <input type="submit"
         value="connect"
         #btnSubmit
         (click)="form.submit()" />
</form>
