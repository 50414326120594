
<app-header [staffLoginShow]="staffCheck"
            [staffLogout]="showDialog"
            (staffRes)="getStaffres($event)"></app-header>
<!--[staffLoginShow]="staffCheck"-->

<div class="background-home body">
  <div *ngIf="loader" class="cs-loader">
    <div class="cs-loader-inner">
      <label> ●</label>
      <label> ●</label>
      <label> ●</label>
      <label> ●</label>
      <label> ●</label>
      <label> ●</label>
    </div>
  </div>
  <app-contact-bar (setLoader)="setLoader($event)"></app-contact-bar>
  <div class="home-btn">
    <img src="/assets/login/images/logo-new.png" />

    <div>
      <div>
        <div class="new-menu" *ngIf="menuSetting?.ncIchibaState != 3">
          <figure>
            <img width="200" src="/assets/home/images/shinshaichiba_menu.png" />
          </figure>
          <div [innerHTML]="textContent1" *ngIf="menuSetting?.ncIchibaState != 2"></div>
          <button class="btn-yellow" *ngIf="menuSetting?.ncIchibaState == 2" (click)="downloadManual('')">このサービスの詳細を見る</button>
          <div class="sub-menu">
            <button class="btn-blue" (mouseenter)="changeText1(1)" (mouseleave)="resetText()"
              (click)="directGoToPage('newcar')" [disabled]="menuSetting?.ncIchibaState == 2">新車カタログ</button>
            <button class="btn-blue" (mouseenter)="changeText1(3)" (mouseleave)="resetText()"
              (click)="checkStaffLogin('stock')" [disabled]="menuSetting?.ncIchibaState == 2">自社在庫検索</button>
            <button class="btn-blue" (mouseenter)="changeText1(2)" (mouseleave)="resetText()"
              (click)="checkStaffLogin('plansearch', 0)" [disabled]="menuSetting?.ncIchibaState == 2">プラン検索</button>
            <button class="btn-blue" (mouseenter)="changeText1(4)" (mouseleave)="resetText()"
              (click)="checkStaffLogin('osusume')" [disabled]="menuSetting?.ncIchibaState == 2">おすすめプラン</button>
            <button class="btn-blue" (mouseenter)="changeText1(6)" (mouseleave)="resetText()"
              (click)="checkStaffLogin('as-csv')" [disabled]="menuSetting?.ncIchibaState == 2">ASNET取込</button>
          </div>
        </div>
        <div class="new-menu" *ngIf="menuSetting?.ucLeaseState != 3">
          <figure>
            <img width="200" src="/assets/home/images/usedcar_menu.png" />
          </figure>
          <div [innerHTML]="textContent2" *ngIf="menuSetting?.ucLeaseState != 2"></div>
          <button class="btn-yellow" *ngIf="menuSetting?.ucLeaseState == 2" (click)="downloadManual('korecarra_service')">このサービスの詳細を見る</button>
          <div class="sub-menu">
            <button class="btn-brown" (mouseenter)="changeText2(1)" (mouseleave)="resetText()"
              (click)="directGoToPage('usedcar')" [disabled]="menuSetting?.ucLeaseState == 2">共有在庫検索</button>
            <button class="btn-brown" (mouseenter)="changeText2(3)" (mouseleave)="resetText()"
              (click)="checkStaffLogin('stock', 1)" [disabled]="menuSetting?.ucLeaseState == 2">自社在庫検索</button>
            <button class="btn-brown" (mouseenter)="changeText2(2)" (mouseleave)="resetText()"
              (click)="checkStaffLogin('plansearch', 1)" [disabled]="menuSetting?.ucLeaseState == 2">プラン検索</button>
            <button class="btn-brown" (mouseenter)="changeText2(5)" (mouseleave)="resetText()"
              (click)="checkStaffLogin('as-csv', 1)" [disabled]="menuSetting?.ucLeaseState == 2">ASNET取込</button>
          </div>
        </div>
        <div class="new-menu" *ngIf="menuSetting?.ncDeliveryState != 3">
          <figure>
            <img width="200" src="/assets/home/images/newcar_menu.png" />
          </figure>
          <div [innerHTML]="textContent3" *ngIf="menuSetting?.ncDeliveryState != 2"></div>
          <button class="btn-yellow" *ngIf="menuSetting?.ncDeliveryState == 2" (click)="downloadManual('')">このサービスの詳細を見る</button>
          <div class="sub-menu">
            <button class="btn-pink" (mouseenter)="changeText3(1)" (mouseleave)="resetText()"
              (click)="directGoToPage('newcar')" [disabled]="menuSetting?.ncDeliveryState == 2">新車カタログ</button>
            <button class="btn-pink" (mouseenter)="changeText3(2)" (mouseleave)="resetText()"
              (click)="checkStaffLogin('plansearch', 0)" [disabled]="menuSetting?.ncDeliveryState == 2">プラン検索</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="news-wrap">
    <div class="box-news">
      <div class="box-news-header">
        新着情報（最新{{countNews}}件）
        <div class="btn-head-wrap">
          <button class="btn-red" (click)="prevPage()">前の10件へ</button>
          <button class="btn-red" (click)="nextPage()">次の10件へ</button>
        </div>
      </div>
      <div class="box-news-content">
        <div class="news-list" *ngFor="let data of newsList;let i=index " (click)="onOpenNewsDetail(data)">
          <p class="box-news-date" *ngIf="data.date">{{data.date | slice:0:4}}.{{data.date | slice:4:6}}.{{data.date | slice:6:8}}</p>
          <p class="box-news-title" *ngIf="data.title">[{{data.title}}</p><p class="last-frame">]</p>
          <p>{{data.body}}</p>
        </div>
        <p class="no-data" *ngIf="newsList?.length == 0">新着情報は、ありません</p>
      </div>
    </div>
  </div>

  <!-- 新車市場ロゴはここに出さないように。新車デリバリーの都合。 -->
  <!-- <div class="footer-login">
    <img src="/assets/login/images/icon-carbell.png" />
  </div> -->

  <div *ngIf="shownew"
       class="modal"
       style="display: block;"
       (click)="onclose()">
    <div class="modal-dialog"
         role="document">
      <div class="modal-header">
        <h5 class="modal-title"
            id="exampleModalLabel"
            *ngIf="newsdetail">{{newsdetail.title}}</h5>
      </div>
      <div class="modal-body">
        <div *ngIf="newsdetail"
             class="comment">
          <p>{{newsdetail.comment}}</p>
        </div>
        <div class="news-body">
          <div *ngIf="newsdetail"
               class="news-date">{{newsdetail.hiduke}}</div>
          <div *ngIf="newsdetail"
               class="news-image">
            <img src="{{newsdetail[0].image1}}">
          </div>
        </div>

      </div>
    </div>
  </div>

  <div style="display:none;">
    <input #ascsvfiledom type="file" (change)="onChangeAsCsvFile($event)" style="display:none;" accept=".csv">
  </div>

  <div class="modal"
     style="display: block;"
     tabindex="-1"
     role="dialog"
     aria-labelledby="exampleModalLabel"
     aria-hidden="true"
     *ngIf="showNewsDetail">

    <div class="modal-dialog"
         role="document">
      <div class="modal-header">
        <span class="close" (click)="onCloseNewsDetail()">×</span>
        <div class="news-title">{{showNews?.title}}</div>
        <div class="news-hiduke" *ngIf="showNews?.date">{{showNews.date | slice:0:4}}.{{showNews.date | slice:4:6}}.{{showNews.date | slice:6:8}}</div>
      </div>
      <div class="modal-body">
        <div class="news-detail">{{showNews?.bodyFull}}</div>
      </div>
      <div class="modal-footer">
        <button class="btnclose"
                (click)="onCloseNewsDetail()">閉じる</button>
      </div>
    </div>
  </div>

  <app-dialogmessenger [showDialog]="showDialog"
                       (oncloses)="onCloseMessage($event)"
                       [title]="errMsg"
    [titleBtn]="'閉じる'"></app-dialogmessenger>

  <app-dialogmenuplan [showDialog]="openDialog"
                      (onCloseDialog)="onCloseDialog($event)"></app-dialogmenuplan>

  <app-purchase-search *ngIf="showDialogOrder"
                       [showCustomerSearch]="showDialogOrder"
                       (chumonvalue)="onchumonData($event)"
                       (close)="oncloseDialogSearch($event)"></app-purchase-search>

  <app-quotation-search *ngIf="showEstimates"
                        [showCustomerSearch]="showEstimates"
                        (datamitsumori)="ongetvaluemistsumor($event)"
                        (close)="oncloseDialogSearch($event)"></app-quotation-search>

  <app-car-search *ngIf="openDialogStock"
                  [sharyouKbn]="2"
                  (onUsedSearchAndView)="onSubmitSearchStock($event)"
    (close)="onCloseDialogStock()"></app-car-search>
