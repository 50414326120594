<div class="login-popup shadowDialog">
<!--
  [2023/11/22] #8594: パスワード欄でEnterを押すと、確定イベントが2回発生するため、keydownで取っている所をkeyupで取るように変更した
-->
  <span>▲</span>
  <strong>担当者ログインしてください。</strong>

  <div class="textbox-pop-login">
    <span>
      <img src="/assets/login/images/icon-user-id.png">
    </span>
    <select id="staffId"
            [(ngModel)]="staffId"
            [ngClass]="{'textbox-err': popupState == false}"
            (focusout)="closePopup()"
            (change)="selectStaffname()"
            (keyup.enter)="keyEnter('pass')">
      <option *ngFor="let item of staffKyoten"
              [ngValue]="item.staffCd">{{item.staffNm}} ({{item.kyotenNm}})</option>
    </select>

  </div>
  <div class="textbox-pop-login">
    <span>
      <img src="/assets/login/images/icon-password.png">
    </span>
    <input placeholder="パスワード" id="pass" type="tel" style="ime-mode: disabled;" (focusout)="closePopup();" (keyup.enter)="keyEnter('btnOk')"
      [appPasswordBox]="staffPass" (passWordShow)="staffPass=$event" autocomplete="off">
  </div>
  <div class="title-checked">IDを記憶する</div>
  <label class="switch">
    <input type="checkbox"
           [(ngModel)]="idstaffRemember">
    <span class="slider round"></span>
  </label>

  <button type="submit" id="btnOk" (click)="secondLogin()">ログイン</button>

</div>