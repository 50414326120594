<div class="modal"
     style="display: block;">
  <div class="modal-dialog">
    <div class="modal-body">
      <div class="row">No.
        <span class="no">{{categoryNo}}</span>
      </div>
      <div class="row">
        <div class="lb">おすすめプラン名称</div>
        <input type="text"
               id="txt-osusumenm"
               [(ngModel)]="planName"
               [autofocus]="true"
               [wordstr]="18"
               [mxLen]="true">
      </div>
      <div class="row">
        <div class="lb">種別</div>
        <select>
          <option>加盟店様登録</option>
        </select>
      </div>
      <br>
      <br>
      <div>
        <div class="btn btn-cancel"
             (click)="onCancel()">キャンセル</div>
        <button class="btn btn-ok"
                (click)="onOK()"
                [disabled]="isAdd">登録</button>
      </div>
    </div>
  </div>
</div>
<!-- <app-dialogerror [display]="displayError" (oncloseDialog)="oncloseDialog()"></app-dialogerror> -->
