<div class="modal"
     style="display: block;"
     tabindex="-1"
     role="dialog"
     aria-hidden="true">
  <div class="modal-dialog"
       role="document">
    <div class="modal-header">エラー</div>
    <div class="modal-body" *ngIf="!showEmpty">
        {{inputname}}は{{zenhanlabel}}{{max}}文字以内で入力してください
        <br><br>
    </div>
    <div class="modal-body" *ngIf="showEmpty">
        {{inputname}}が、未入力です。
        <br><br>
    </div>
    <div class="modal-footer">
      <button class="btn" (click)="oncloseDialog()" dialog-maxlength-btn>OK</button>
    </div>
  </div>
</div>