<div class="background-login">
    <div *ngIf="loader" class="cs-loader">
        <div class="cs-loader-inner">
          <label> ●</label>
          <label> ●</label>
          <label> ●</label>
          <label> ●</label>
          <label> ●</label>
          <label> ●</label>
        </div>
    </div>
  <!--<header>
		<div class="container-login">
			<div class="header-login">
				<div class="logo">
					<img src="/assets/login/images/logo-head.png" />
				</div>
				<div class="call-contact">
					<a href="#" target="_blank" class="btn-faq"><img src="/assets/login/images/icon-faq.png"> リモートサポート</a>
					<img src="/assets/login/images/icon-call.png" class="icon-call">
				</div>
			</div>
		</div>
	</header>-->

  <header>

  </header>

  <app-contact-bar (setLoader)="setLoader($event)"></app-contact-bar>

  <div class="container">

    <div class="col-md-12">
      <div class="login-form">
        <img src="/assets/login/images/logo-new.png" />
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm)" autocomplete="off">

          <div class="input-form">
            <div class="form-group row" [ngClass]="{ 'has-success': loginForm.controls.id.valid,
							'has-error': !loginForm.controls.id.valid && loginForm.controls.id.dirty}">


              <div class="txtbox">
                <span>
                  <img src="/assets/login/images/icon-username.png" />
                </span>
                <input #inputid type="tel" id="inputid" name="inputid" placeholder="ID" formControlName="id" class="form-control input-custom"
                  (keydown.enter)="changeFocus()" [(ngModel)]="username" autocomplete="nope">
              </div>

            </div>
            <div class="form-group row" [ngClass]="{ 'has-success': loginForm.controls.pass.valid,
							'has-error': !loginForm.controls.pass.valid && loginForm.controls.pass.dirty}">
              <div class="txtbox">
                <span>
                  <img src="/assets/login/images/icon-password.png" />
                </span>
                <input #pass id="pass" type="password" placeholder="パスワード" class="password-textbox form-control input-custom" formControlName="pass"
                  autocomplete="new-password">
              </div>

            </div>
            <!--<input type="checkbox" class="checkbox"><span class="checkbox-fix"> IDを記憶する</span>-->

            <app-validation-message *ngIf="!loginForm.controls.id.valid && loginForm.controls.id.dirty" class="help-block" [control]="loginForm.controls.id"
              [field]="'アカウントID'">
            </app-validation-message>

            <app-validation-message *ngIf="!loginForm.controls.pass.valid && loginForm.controls.pass.dirty" class="help-block" [control]="loginForm.controls.id"
              [field]="'パスワード'">
            </app-validation-message>

            <div class="title-checked">IDを記憶する</div>
            <label class="switch">
              <input type="checkbox" [checked]="idRemember" (change)="idRemember = !idRemember">
              <span class="slider round"></span>
            </label>
          </div>
          <button type="submit" class="btn-submit">ログイン</button>
          <div class="forgotpass">
            <!-- <a href="#">ID/パスワードをお忘れの方はこちら</a> -->
          </div>
          <div class="error-return">{{ checkError }}</div>
        </form>
      </div>
    </div>
  </div>
  <!--<div class="text-login-page">
		【ご注意】販売システムのご利用が終わりましたら、必ず右上のログアウトボタンで終了して下さい。<br>
		ブラウザ右上の閉じるボタンで閉じたり、お気に入りから別のページにジャンプすると、<br>
		システムが貴社のIDでログインされたままとみなすため、しばらくの間、再度ログインすることが出来なくなります。<br>
		又、毎日21時~22時にメンテナンスを行いますので、ご使用できない場合があります。
	</div>-->
  <!-- 新車市場ロゴはここに出さないように。新車デリバリーの都合。 -->
  <!-- <div class="footer-login">
    <img src="/assets/login/images/icon-carbell.png" />
  </div> -->
</div>