<span [ngClass]="{'ui-calendar':true,'ui-calendar-w-btn':showIcon}"
      [ngStyle]="style"
      [class]="styleClass">
    <ng-template [ngIf]="!inline">
        <div class="adjust-button">
            <input Select="true"
                   #inputfield
                   type="tel"
                   [attr.id]="inputId"
                   [attr.name]="name"
                   [attr.required]="required"
                   [value]="inputFieldValue"
                   (focus)="onInputFocus($event)"
                   (keydown.enter)="seadEnterKey($event)"
                   (keydown)="onInputKeydown($event)"
                   (click)="onopenDatepicker()"
                   (blur)="onInputBlur($event)"
                   [readonly]="readonlyInput"
                   (input)="onUserInput($event)"
                   [ngStyle]="inputStyle"
                   [class]="inputStyleClass"
                   [placeholder]="placeholder||''"
                   [disabled]="disabled"
                   [attr.tabindex]="tabindex"
                   [ngClass]="'ui-inputtext ui-state-default ui-corner-all'"
                   style="ime-mode: disabled"
                   (keypress)="onkeypress($event)"
                   (keyup)="onkeyup($event)">
            <button type="button"
                    [icon]="icon"
                    pButton
                    *ngIf="showIcon"
                    (click)="onButtonClick($event,inputfield)"
                    class="ui-datepicker-trigger ui-calendar-button"
                    [ngClass]="{'ui-state-disabled':disabled}"
                    [disabled]="disabled"
                    tabindex="-1"></button>
        </div>
    </ng-template>
    <div #datepicker
         class="ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all"
         [ngClass]="{'ui-datepicker-inline':inline,'ui-shadow':!inline,'ui-state-disabled':disabled,'ui-datepicker-timeonly':timeOnly}"

         [ngStyle]="{'display': inline ? 'inline-block' : (overlayVisible ? 'block' : 'none')}"
         (click)="onDatePickerClick($event)"
         [@overlayState]="inline ? 'visible' : (overlayVisible ? 'visible' : 'hidden')">
        <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all"
             *ngIf="!timeOnly && (overlayVisible || inline)">
            <ng-content select="p-header"></ng-content>
            <a class="ui-datepicker-prev ui-corner-all"
               href="#"
               (click)="prevMonth($event)">
                <span class="fa fa-angle-left"></span>
            </a>
            <a class="ui-datepicker-next ui-corner-all"
               href="#"
               (click)="nextMonth($event)">
                <span class="fa fa-angle-right"></span>
            </a>
            <div class="ui-datepicker-title">
                <span class="ui-datepicker-month"
                      *ngIf="!monthNavigator">{{locale.monthNames[currentMonth]}}</span>
                <select class="ui-datepicker-year"
                        *ngIf="yearNavigator"
                        (change)="onYearDropdownChange($event.target.value)">
                    <option [value]="year"
                            *ngFor="let year of yearOptions"
                            [selected]="year == currentYear">{{year}}</option>
                </select>
                <select class="ui-datepicker-month"
                        *ngIf="monthNavigator"
                        (change)="onMonthDropdownChange($event.target.value)">
                    <option [value]="i"
                            *ngFor="let month of locale.monthNames;let i = index"
                            [selected]="i == currentMonth">{{month}}</option>
                </select>

                <span class="ui-datepicker-year"
                      *ngIf="!yearNavigator">{{currentYear}}</span>
            </div>
        </div>
        <table class="ui-datepicker-calendar"
               *ngIf="!timeOnly && (overlayVisible || inline)">
            <thead>
                <tr>
                    <th scope="col"
                        *ngFor="let weekDay of weekDays;let begin = first; let end = last">
                        <span>{{weekDay}}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let week of dates">
                    <td *ngFor="let date of week"
                        [ngClass]="{'ui-datepicker-other-month ui-state-disabled':date.otherMonth,
                        'ui-datepicker-current-day':isSelected(date),'ui-datepicker-today':date.today}">
                        <a class="ui-state-default"
                           href="#"
                           *ngIf="date.otherMonth ? showOtherMonths : true"
                           [ngClass]="{'ui-state-active':isSelected(date), 'ui-state-highlight':date.today, 'ui-state-disabled':!date.selectable}"
                    
                           (click)="onDateSelect($event,date)">
                            <span *ngIf="!dateTemplate">{{date.day}}</span>
                            <ng-template [pTemplateWrapper]="dateTemplate"
                                         [item]="date"
                                         *ngIf="dateTemplate"></ng-template>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="ui-timepicker ui-widget-header ui-corner-all"
             *ngIf="showTime||timeOnly">
            <div class="ui-hour-picker">
                <a href="#"
                   (click)="incrementHour($event)">
                    <span class="fa fa-angle-up"></span>
                </a>
                <span [ngStyle]="{'display': currentHour < 10 ? 'inline': 'none'}">0</span>
                <span>{{currentHour}}</span>
                <a href="#"
                   (click)="decrementHour($event)">
                    <span class="fa fa-angle-down"></span>
                </a>
            </div>
            <div class="ui-separator">
                <a href="#">
                    <span class="fa fa-angle-up"></span>
                </a>
                <span>:</span>
                <a href="#">
                    <span class="fa fa-angle-down"></span>
                </a>
            </div>
            <div class="ui-minute-picker">
                <a href="#"
                   (click)="incrementMinute($event)">
                    <span class="fa fa-angle-up"></span>
                </a>
                <span [ngStyle]="{'display': currentMinute < 10 ? 'inline': 'none'}">0</span>
                <span>{{currentMinute}}</span>
                <a href="#"
                   (click)="decrementMinute($event)">
                    <span class="fa fa-angle-down"></span>
                </a>
            </div>
            <div class="ui-separator"
                 *ngIf="showSeconds">
                <a href="#">
                    <span class="fa fa-angle-up"></span>
                </a>
                <span>:</span>
                <a href="#">
                    <span class="fa fa-angle-down"></span>
                </a>
            </div>
            <div class="ui-second-picker"
                 *ngIf="showSeconds">
                <a href="#"
                   (click)="incrementSecond($event)">
                    <span class="fa fa-angle-up"></span>
                </a>
                <span [ngStyle]="{'display': currentSecond < 10 ? 'inline': 'none'}">0</span>
                <span>{{currentSecond}}</span>
                <a href="#"
                   (click)="decrementSecond($event)">
                    <span class="fa fa-angle-down"></span>
                </a>
            </div>
            <div class="ui-ampm-picker"
                 *ngIf="hourFormat=='12'">
                <a href="#"
                   (click)="toggleAMPM($event)">
                    <span class="fa fa-angle-up"></span>
                </a>
                <span>{{pm ? 'PM' : 'AM'}}</span>
                <a href="#"
                   (click)="toggleAMPM($event)">
                    <span class="fa fa-angle-down"></span>
                </a>
            </div>
        </div>
        <div class="ui-datepicker-buttonbar ui-widget-header"
             *ngIf="showButtonBar">
            <div class="ui-g">
                <div class="ui-g-6">
                    <button type="button"
                            [label]="_locale.today"
                            (click)="onTodayButtonClick($event)"
                            pButton
                            [ngClass]="[todayButtonStyleClass]"></button>
                </div>
                <div class="ui-g-6">
                    <button type="button"
                            [label]="_locale.clear"
                            (click)="onClearButtonClick($event)"
                            pButton
                            [ngClass]="[clearButtonStyleClass]"></button>
                </div>
            </div>
        </div>
        <ng-content select="p-footer"></ng-content>
    </div>
</span>
