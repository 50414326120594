<header>
  <div *ngIf="loader"
       class="cs-loader">
    <div class="cs-loader-inner">
      <label> ●</label>
      <label> ●</label>
      <label> ●</label>
      <label> ●</label>
      <label> ●</label>
      <label> ●</label>
    </div>
  </div>
  <div class="container-wrap headerbox">
    <div class="logo-wrap">
      <a [routerLink]="['/home']"
         class="">
        <img src="../../assets/home/images/logo-new.png" />
      </a>
      <div class="manage-btn">
        <div class="btn-support" (click)="linkToSupport()">
          <div class="img-support"></div>
          <span>システムサポート</span>
        </div>
        <!-- <img src="../../assets/home/images/support.png"
             alt="システムサポート"
             class="link-support"
             (click)="linkToSupport()" /> -->
        <img src="../../assets/home/images/manual.png"
             alt="操作マニュアル"
             class="link-manual"
             (click)="downloadManual()" />
      </div>
    </div>
    <label class="label-status"
           *ngIf="isOsusume && !isEditOsusume">おすすめ
      <br>プラン登録済</label>
    <label class="label-status"
           *ngIf="isOsusume && isEditOsusume">おすすめ
      <br>プラン編集中</label>
    <label class="label-status padding"
           *ngIf="mitsumoriDeleted">削除済</label>
    <label class="label-status"
           *ngIf="chumonCancel">注文書
      <br>受注キャンセル</label>
    <div class="login-warp">
      <!-- <div class="loginbox">
        <img src="../../assets/home/images/icon-home.png" />
        <span>店舗</span> 東京支店
        <a class="btn-login-green">ログイン中</a>
        <img class="login" (click)="logout()" src="../../assets/home/images/icon-login.png" />
      </div> -->

      <div class="loginbox">
        <img src="../../assets/home/images/icon_company.png" />
        <span class="shaten-head">会社</span>
        <span class="login-val v-middle">{{shatenNm}}</span>
        <a class="btn-login-green">ログイン中</a>
        <img class="login"
             (click)="logout()"
             src="../../assets/home/images/icon-login.png" />
      </div>
      <div class="loginbox">
        <div class="inline">
          <div class="box-row">
            <img src="../../assets/home/images/icon-home.png" />
            <span class="loginbox-head v-bottom">店舗</span>
            <span class="login-val v-bottom"
                  *ngIf="statusLogin">{{kyotenNm}}</span>
          </div>
          <div class="box-row">
            <img src="../../assets/home/images/icon-user.png" />
            <span class="loginbox-head v-bottom">担当者</span>
            <span class="login-val v-bottom"
                  *ngIf="statusLogin">{{staffName}}</span>
          </div>
        </div>
        <div class="btn-warpper">
          <!-- <div class="login-popup" *ngIf="showStaffLogin">
              <span>▲</span>
              <strong>担当者ログインしてください。</strong>
              <div class="textbox-pop-login">
                <span>
                  <img src="/assets/login/images/icon-user-id.png">
                </span>
                <select id="staffId" [(ngModel)]="staffId" [ngClass]="{'textbox-err': popupState == false}" (focusout)="closePopup()" (change)="selectStaffname($event)">
                  <option *ngFor="let item of staffKyoten" [ngValue]="item.staffCd">{{item.staffNm}} ({{item.kyotenNm}})</option>
                </select>
              </div>
              <div class="textbox-pop-login">
                <span>
                  <img src="/assets/login/images/icon-password.png">
                </span>
                <input placeholder="パスワード" type="password" [(ngModel)]="staffPass" [ngClass]="{'textbox-err': popupState == false}" (focusout)="closePopup()">
              </div>
              <div class="title-checked">IDを記憶する</div>
              <label class="switch">
                <input type="checkbox" [(ngModel)]="idstaffRemember" (change)="rememberStaffid($event.target.checked)">
                <span class="slider round"></span>
              </label>
              <button (click)="secondLogin()">ログイン</button>
            </div> -->
          <app-dialog-stafflogin *ngIf="showStaffLogin"
                                 (onLogin)="onClosestafflogin($event)"></app-dialog-stafflogin>

          <a *ngIf="!statusLogin"
             class="btn-login-blue"
             (click)="clickBtnStaffLogin()">ログイン</a>
          <a *ngIf="statusLogin"
             class="btn-login-green">ログイン中</a>
        </div>
        <button (click)="secondLogout()">
          <img [ngClass]="{ 'logout' : statusLogin == false, 'login' : statusLogin == true }"
               src="../../assets/home/images/icon-login.png" />
        </button>
      </div>
      <div class="btn-manual"
           #btnManual>
        <a *ngIf="showGyoumuKanri"
           href="javascript:void(0);"
           (click)="ToggleBusinessManage()">
          <img src="../../assets/home/images/icon-book.png" />
          <span>業務管理</span>
        </a>
      </div>
      <div class="btn-manual"
           [ngStyle]="{'border-left' : showGyoumuKanri ? null : '1px solid #ccc'}"
           #btnSetting>
        <a href="javascript:void(0);"
           (click)="ToggleSetting()">
          <img src="../../assets/home/images/icon_haguruma.png" />
          <span>マスタ設定</span>
        </a>
      </div>
      <app-business-manage-dialog *ngIf="isOpenBussinessManage"
                                  (close)="businessClose()"
                                  (clickoutside)="clickOutBussiness()"></app-business-manage-dialog>
      <app-setting-dialog *ngIf="isOpenSetting"
                          [visibleSetting]="masterMenuVisibleSetting"
                          (close)="settingClose()"
                          (clickoutside)="clickOutSetting()"
                          (newInfo)="onShowNewInfo($event)"
                          (toggleOsusumeSetting)="toggleOsusumeSetting($event)"></app-setting-dialog>
      <app-osusume-setting *ngIf="isOpenOsusumeSetting"
                           (close)="toggleOsusumeSetting(false)"></app-osusume-setting>
      <app-dialogmessenger [showDialog]="showDialog"
                           (oncloses)="onCloseMessage($event)"
                           [title]="errMsg"
                           [titleBtn]="'閉じる'"></app-dialogmessenger>
    </div>

  </div>

  <!--<div class="container-wrap">
    <div class=" header-logo"> <a [routerLink]="['/home']"><img src="../../assets/home/images/logo-head.png"/></a> </div>
    <div class="head-right">
    <div class="contact-support"><a href="#" class="header-remort">リモートサポート</a></div>
    <a class="header-logout" (click)="logout()"><i class="glyphicon glyphicon-off"></i> ログアウト</a>
    <div class="header-support images-support"><img src="../../assets/common/images/header-support.png"/></div>
    
    </div>
    <a href="#" class="header-logo"><img src="../../assets/common/images/header-logo.png" width="310" height="47" alt="くるま辞典"/></a>
    <a href="#" class="header-logout"><i class="glyphicon glyphicon-off"></i> ログアウト</a>
    <div class="header-tel"><img src="../../assets/common/images/header-support.png" width="194" height="39" alt=""/></div>
    <a href="#" class="header-remort">リモートサポート</a>-->
  <!--<ul class="header-link">
    	<li><a href="#">ホーム</a></li>
    	<li><a href="#">マニュアル</a></li>
    	<li><a href="#">ログアウト</a></li>
    </ul>


  </div>-->
</header>

<app-new-information *ngIf='newInfo'
                     (close)="onShowNewInfo($event)"></app-new-information>
