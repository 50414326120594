<div class="modal" style="display: block;" tabindex="-1" role="dialog">
  <div class="modal-dialog dialog-msg" role="document">
    <div class="modal-header">インフォメーション</div>
    <div class="modal-body">
      これ以上選択できません
      <br><br>
    </div>
    <div class="modal-footer">
      <button class="btn" (click)="onClose()">OK</button>
    </div>
  </div>
</div>