<div class="modal"
     [@dialog]="showDialog"
     *ngIf="showDialog"
     style="display: block;"
     tabindex="-1"
     role="dialog"
     aria-labelledby="exampleModalLabel"
     aria-hidden="true">

  <div class="modal-dialog quotation-print"
       role="document">
    <div class="modal-header"
         *ngIf="!offheader">
      <h5 class="modal-title pull-left" style="color: black">{{titleheader}}</h5>
      <button type="button"
              (click)="closeBtn('close')"
              class="close pull-right"
              aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{title}}</p>
      <p *ngIf="titleHtml!=''" [innerHtml]="titleHtml"></p>
    </div>
    <div class="modal-footer">
      <div class="btnclose">
        <button #confirmBtn type="button"
                (click)="closeBtn('ok')"
                class="confirmbtn">{{titleBtn}}</button>
        <button class="closebtn"
                (click)="closeBtn('close')"
                *ngIf="showCloseBtn">{{titleClose}}</button>
      </div>
    </div>
  </div>

</div>
