<div class="modal"
     style="display: block;"
     tabindex="-1"
     role="dialog"
     aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog quotation"
       role="document">
    <div class="modal-body">
      <div class="head">
        <!-- <input type="checkbox">
        <p>装備参照</p> -->
        <div>
          <p>※ 12個まで選択可能</p>
          <label>
            現在選択数：
            <label></label>{{equipments.length}} 個
          </label>
        </div>
      </div>
      <div class="list">
        <ul class="box">
          <li *ngFor="let sb of soubi;let i = index" [class.active]="isCheck(sb.kbn)" (click)="selectedSoubi(sb.kbn,i)">
            <input type="checkbox" id="chk-eq-{{sb.kbn}}"
                   [checked]="isCheck(sb.kbn)">
            <label for="chk-eq-{{sb.kbn}}" class="lb-chk" (click)="selectedSoubi(sb.kbn,i)">
              <img src="assets/plan/usedcar/image/soubi/3123_{{sb.kbn}}.jpg" alt="">{{sb.text1}}</label>
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <div class="footer">
        <button class="btn btn_pink" (click)="sendSoubi()">選択</button>
        <button class="btn" (click)="clearSelected()">取消</button>
        <button class="btn" (click)="onClose()">閉じる</button>
      </div>
    </div>
  </div>
</div>

<app-dialog-max-equipment *ngIf="!overSelect" (close)="onCloseAlt()"></app-dialog-max-equipment>
