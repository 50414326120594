<div *ngIf="showDialog" class="modal" style="display: block;" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog quotation-print" role="document">
    <div class="modal-header">
      <!-- <h5 class="modal-title pull-left">インフォメーション</h5> -->
      <button type="button" class="close pull-right" aria-label="Close" (click)="onclose($event,0)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="rowmodel">
        <button class="btm-srcbt" (click)="onclose($event,1)">見積書検索</button>
      </div>
      <div class="rowmodel">
        <button class="btm-srcbt" (click)="onclose($event,2)">注文書検索</button>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btnclose" (click)="onclose($event,0)">閉じる</button>
    </div>
  </div>
</div>