<div class="modal"
     style="display: block;"
     tabindex="-1"
     role="dialog"
     aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog car-search wide-dialog"
       role="document">
    <div class="modal-body">
      <span aria-hidden="true" (click)="onClose()" class="close">&times;</span>
      <div class="table-wrap">
        <div class="scroll-table">
          <table class="table">
            <thead class="table-header">
              <tr>
                <th align="center" class="w82">
                  <span>社店</span>
                </th>
                <th align="center" class="w83">
                  <span>管理番号</span>
                </th>
                <th align="center" class="w82">
                  <span>車両区分</span>
                </th>
                <th align="center" class="w82">
                  <span>入庫分類</span>
                </th>
                <th align="center" class="w83">
                  <span>車名</span>
                </th>
                <th align="center" class="w83">
                  <span>グレード</span>
                </th>
                <th align="center" class="w83">
                  <span>型式</span>
                </th>
                <th align="center" class="w83">
                  <span>販売価格</span>
                </th>
                <th align="center" class="w82">
                  <span>販売区分</span>
                </th>
                <th align="center" class="w83">
                  <span>車台番号</span>
                </th>
                <th align="center" class="w82">
                  <span>色</span>
                </th>
                <th align="center" class="w82">
                  <span>在庫拠点</span>
                </th>
                <th align="center" class="w82">
                  <span>年式</span>
                </th>
              </tr>
            </thead>
            <tbody *ngIf="zaikoItemslist">
              <tr class="space">
                <td colspan="13"></td>
              </tr>
              <tr *ngFor="let data of zaikoItemslist; let i = index" class="myrow" (click)="setClickedRow(data,i)" (dblclick)="onDbclickTable(data)"
                [class.active]="i == selectedRow">
                <td class="w83" align="center">{{data.shatenCd}}</td>
                <td class="w83" align="center">{{data.kanriNo}}</td>
                <td class="w82" align="center">{{data.sharyouKbnText}}</td>
                <td class="w82" align="center">{{data.nyuukoBunrui}}</td>
                <td class="w83" align="center">{{data.modelNm}}</td>
                <td class="w83" align="center">{{data.gradeNm}}</td>
                <td class="w83" align="center">{{data.katashiki}}</td>
                <td class="w83" align="center" *ngIf="data.hanbaiPriceZk">{{data.hanbaiPriceZk}}</td>
                <td class="w83" align="center" *ngIf="!data.hanbaiPriceZkValue">{{data.hanbaiPriceZkValue}}</td>
                <td class="w82" align="center">{{data.hanbaiKbnNm}}</td>
                <td class="w83" align="center">{{data.shadaiNo}}</td>
                <td class="w82" align="center">{{data.keitooColor}}</td>
                <td class="w82" align="center">{{data.zaikoKyoten}}</td>
                <td class="w82" align="center">{{data.nenshiki}}</td>
              </tr>
              <!-- zaikoItemslist -->
            </tbody>
          </table>
        </div>
      </div>

      <div class="option">
        <div class="option-1">
          <div>
            <p>車両区分</p>
            <div>
              <select class="w220" [(ngModel)]="selectshayou" (change)="onchangshayou($event)">
                <option></option>
                <option *ngFor="let data of dataSharyou" [ngValue]="data"> {{data.text}} </option>
              </select>
            </div>
          </div>
          <div>
            <p>メーカー</p>
            <div>
              <input type="text" readonly [value]="zaiko.makerNm" class="width-input w220" />
              <button (click)="onShowdialogMaker()">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
          <div>
            <p>販売価格</p>
            <div>
              <input [OnlyNumber]="true" type="tel" currencyMask [options]="{ prefix: '', thousands: ',',precision  : '0'}" [(ngModel)]="zaiko.hanbaiPriceFrom "
                maxlength="11" class="w100 ime_d"> ~
              <input type="tel" [OnlyNumber]="true" currencyMask [options]="{ prefix: '', thousands: ',',precision  : '0'}" [(ngModel)]="zaiko.hanbaiPriceTo"
                maxlength="11" class="w100 ime_d">
            </div>
          </div>
          <div>
            <p>型式</p>
            <div>
              <input type="tel" [Select]="true" [(ngModel)]="zaiko.katashiki" maxlength="20" class="w220 ime_d" />
            </div>
          </div>
          <div>
            <p>車台番号</p>
            <div>
              <input type="tel" [Select]="true" [(ngModel)]="zaiko.shadaiNo" maxlength="20" class="w220 ime_d" />
            </div>
          </div>
        </div>

        <div class="option-2">
          <div>
            <p>管理番号</p>
            <div>
              <button (click)="onshowshaten()">
                <i class="glyphicon glyphicon-search"></i>
              </button>
              <input type="text" class="w70" [value]="zaiko.shatenCd" readonly>
              <input #kanriNo [OnlyNumber]="true" type="tel" maxlength="10" [(ngModel)]="zaiko.kanriNo" (focusout)="setZero()" (keydown.enter)="kanriNoEnter()" class="w108 ime_d">
            </div>
          </div>
          <div>
            <p>車名</p>
            <div>
              <input type="text" class="w230" readonly [value]="zaiko.modelNm" [disabled]="!zaiko.makerCd">
              <button (click)="showDialogModel()" [disabled]="!zaiko.makerCd">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
          <div>
            <p>年式</p>
            <div>
              <app-dropdowncalendar [defaultDate]="'off'" [hiddenDate]="true" (onfocusouts)="onnenshikiFrom($event)" formatdate="yy/mm"
                [style]="{'width' : '62px'}"></app-dropdowncalendar>
              ~
              <app-dropdowncalendar [defaultDate]="'off'" [hiddenDate]="true" (onfocusouts)="onnenshikiTo($event)" formatdate="yy/mm" [style]="{'width' : '62px'}"></app-dropdowncalendar>
            </div>

          </div>
          <div class="h30">
          </div>
          <div>
            <p>プレート区分</p>
            <div>
              <select class="w200" [(ngModel)]="selectPlate" (change)="onPlate()">
                <option *ngFor="let data of dataPlate" [ngValue]="data"> {{data.text}} </option>
              </select>
            </div>
          </div>
        </div>

        <div class="option-3">
          <div>
            <p>販売区分</p>
            <div>
              <select class="w270" disabled [(ngModel)]="selectHanbai" (change)="onchangeHanbai()">
                <option *ngFor="let data of dataHanbai" [ngValue]="data"> {{data.text1}} </option>
              </select>
            </div>
          </div>
          <div>
            <p>入庫分類</p>
            <div>

              <input type="text" readonly [value]="zaiko.bunruiname" class="w270">
              <button (click)="displaynyukoBunrul = true">
                <i class="glyphicon glyphicon-search"></i>
              </button>
              <!-- <select >
					      <option> H </option>
              </select>
              <input type="text" class="w73"> -->
              <!-- <select>
					      <option> H </option>
              </select>
              <input type="text" class="w73"> -->
            </div>
          </div>
          <div>
            <p>系統色</p>
            <div>
              <input type="text" class="w270" readonly [value]="zaiko.colorname" />
              <button (click)="onshowColor()">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
          <div>
            <p>在庫拠点</p>
            <div>
              <input type="text" readonly [value]="zaiko.kyotenname" class="w270" />
              <button (click)="displaykyoten = true">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
          </div>
          <div>
            <p>プレート番号</p>
            <div>
              <select [(ngModel)]="selectPlateAreas" (change)="onchangePlateAreas()">
                <option *ngFor="let data of dataPlateAreas" [ngValue]="data">{{data.plateAreaNm}}</option>
              </select>
              <input type="tel" [Select]="true" maxlength="3" [(ngModel)]="zaiko.plateModel" class="w58 ime_d">
              <input Select="true" type="text" maxlength="1" [(ngModel)]="zaiko.plateKana" style="ime-mode: active" class="w58">
              <input type="tel" [Select]="true" maxlength="4" [(ngModel)]="zaiko.plateBangou" class="w58 ime_d">
            </div>
          </div>
        </div>

      </div>
      <div class="row-equipment">
        <p>装備</p>
        <div style="display: flex;">
          <button class="btn-eq" (click)="onShowDialog(1)">装備</button>
          <div class="boxs" *ngIf="zaiko.equipment">
            <div *ngFor="let data of zaiko.equipment">
              <img src="assets/plan/usedcar/image/soubi/3123_{{data}}.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="modal-footer btn-foot" style="justify-content: center !important;">
      <button [disabled]="!selectlist" (click)="onShowDialog(2)">詳細情報</button>
      <div>
        <a href="javascript:void(0);" class="btn" (click)="onClose()">閉じる</a>
        <a href="javascript:void(0);" class="btn btn_pink" (click)="onconfirmZaiko()">選択</a>
        <a href="javascript:void(0);" class="btn btn_blue" (click)="onsearch()">検索</a>
      </div>
    </div>
  </div>
</div>

<app-equipment-modal *ngIf="showEquipment" (onequipmentsData)="onequipmentsData($event)" (close)="closeFilter()" [data]="selectlist"></app-equipment-modal>
<app-detail-modal *ngIf="showDetail" (close)="closeFilter()" [data]="selectlist"></app-detail-modal>


<p-dialog header="メーカー" [(visible)]="display" modal="modal" width="350" (onHide)="oncloseDialog($event)">


  <!-- select usedcar -->
  <div class="select-usedcar" *ngIf="makerdisplay">
    <!-- <p class="title-select">メーカー一覧</p> -->
    <div class="title-table cursor-point" (click)="filterMakerList()">全体</div>
    <div class="title-table cursor-point" (click)="filterMakerList(11)">英数</div>
    <div class="table-show-text">
      <div class="divrow">
        <div class="divcell" (click)="filterMakerList(1)">あ</div>
        <div class="divcell" (click)="filterMakerList(2)">か</div>
        <div class="divcell" (click)="filterMakerList(3)">さ</div>
        <div class="divcell" (click)="filterMakerList(4)">た</div>
        <div class="divcell" (click)="filterMakerList(5)">な</div>
      </div>
      <div class="divrow">
        <div class="divcell" (click)="filterMakerList(6)">は</div>
        <div class="divcell" (click)="filterMakerList(7)">ま</div>
        <div class="divcell" (click)="filterMakerList(8)">や</div>
        <div class="divcell" (click)="filterMakerList(9)">ら</div>
        <div class="divcell" (click)="filterMakerList(10)">わ</div>
      </div>
    </div>
    <!-- list type car -->
    <div class="box-type">
      <div class="list-type">
        <div *ngFor="let maker of makerData; let i = index" (click)="makerSelected(maker,i,$event)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="makerData[i].stat"></i>
            <span class="m-l-5">{{maker.makerNm}}</span>
          </label>
        </div>
      </div>
      <div class="list-type" *ngIf="!makerData">
        <label class="type-check">
          <span>Data no found.</span>
        </label>
      </div>

    </div>

  </div>
</p-dialog>

<p-dialog header="車名" [(visible)]="modeldisplay" modal="modal" width="350" (onHide)="oncloseDialog($event)">
  <div class="select-usedcar" *ngIf="modeldisplay">
    <div class="title-table cursor-point" (click)="filterModelList()">全体</div>
    <div class="title-table cursor-point" (click)="filterModelList(11)">英数</div>
    <div class="table-show-text">
      <div class="divrow">
        <div class="divcell cursor-point" (click)="filterModelList(1)">あ</div>
        <div class="divcell cursor-point" (click)="filterModelList(2)">か</div>
        <div class="divcell cursor-point" (click)="filterModelList(3)">さ</div>
        <div class="divcell cursor-point" (click)="filterModelList(4)">た</div>
        <div class="divcell cursor-point" (click)="filterModelList(5)">な</div>
      </div>
      <div class="divrow">
        <div class="divcell cursor-point" (click)="filterModelList(6)">は</div>
        <div class="divcell cursor-point" (click)="filterModelList(7)">ま</div>
        <div class="divcell cursor-point" (click)="filterModelList(8)">や</div>
        <div class="divcell cursor-point" (click)="filterModelList(9)">ら</div>
        <div class="divcell cursor-point" (click)="filterModelList(10)">わ</div>
      </div>
    </div>

    <!-- list type car -->
    <div class="box-type" *ngIf="modeldisplay">
      <div class="list-type" *ngIf="modelData">
        <div *ngFor="let model of modelData; let i = index" (click)="modelSelected(model,i,$event)">
          <label class="type-check">
            <div class="icon-sqare-white"></div>
            <i class="icon-check-c glyphicon glyphicon-ok" *ngIf="modelData[i].stat"></i>
            <span class="m-l-5">{{model.modelNm}}</span>
          </label>
        </div>
      </div>
      <div class="list-type" *ngIf="!modelData">
        <label class="type-check">
          <span>Data no found.</span>
        </label>
      </div>
    </div>
  </div>
</p-dialog>

<div *ngIf="displayshaten">
  <p-dialog header="社店" [(visible)]="displayshaten" modal="modal" [width]="300" [height]="550" (onHide)="oncloseshaten($event)">
    <p-dataTable [value]="zaikoShatenlist" selectionMode="single" (onRowSelect)="onRowSelect($event)">
      <p-column field="shatenNm"></p-column>
    </p-dataTable>
  </p-dialog>
</div>

<div *ngIf="displaykeitooColor">
  <p-dialog header="系統色" [(visible)]="displaykeitooColor" modal="modal" [width]="300" [height]="550" (onHide)="oncloseColor($event)">
    <p-dataTable [value]="keitooColorlist" selectionMode="single" scrollable="true" scrollHeight="450px" (onRowSelect)="onRowSelectColor($event)">
      <p-column field="text"></p-column>
    </p-dataTable>
  </p-dialog>
</div>


<div *ngIf="displaykyoten">
  <p-dialog header="拠点" [(visible)]="displaykyoten" modal="modal" [width]="300" [height]="550" (onHide)="oncloseDialog($event)">
    <p-dataTable [value]="datakyotens" selectionMode="single" scrollable="true" scrollHeight="450px" (onRowSelect)="onRowSelectKyoten($event)">
      <p-column field="kyotenNm"></p-column>
    </p-dataTable>
  </p-dialog>
</div>


<div *ngIf="displaynyukoBunrul">
  <p-dialog header="入庫分類" [(visible)]="displaynyukoBunrul" modal="modal" [width]="300" [height]="550" (onHide)="onclosenyukoBunrul($event)">
    <p-dataTable [value]="datanyuuko" selectionMode="single" scrollable="true" scrollHeight="450px" (onRowSelect)="onRownyuuko($event)">
      <p-column field="bunruiNm"></p-column>
    </p-dataTable>
  </p-dialog>
</div>
