<!-- <div class="contact">
  <div>
    <img src="/assets/login/images/icon-contact.png" />
    <span>リモートサポート</span>
    <span>受付 9:00〜19:00</span>
  </div>
  <div>
    <img src="/assets/login/images/icon-tel.png" />
    <span>086-242-0810</span>
    <span>受付 9:00〜19:00</span>
  </div>
</div> -->
<div class="link-shutokuzei">
  <!--
  <a href="javascript:void(0);" (click)="downloadPDF('2019_shutokuzei')">【重要なお知らせ】　2019年度エコカー減税改正に関する対応について</a>
  <a href="javascript:void(0);" (click)="downloadPDF('2019_zeisei')">【重要なお知らせ】　2019年10月各種税制改正に関するシステム対応について</a>
  <a href="javascript:void(0);" (click)="downloadPDF('shinsha_haishin_yotei')">【重要なお知らせ】　上記に伴う新車データの更新スケジュールについて</a>
  <a href="javascript:void(0);" (click)="downloadPDF('2021_zeisei')">【重要なお知らせ】2021年度エコカー減税改正に関する対応について</a>
  <a href="javascript:void(0);" (click)="downloadPDF('2023_zeisei')">【重要なお知らせ】2023年度エコカー減税改正に関する対応について</a>
  -->
  <!-- <a href="javascript:void(0);" (click)="downloadPDF('2024_zeisei')">【重要なお知らせ】2024年度エコカー減税改正に関する対応について</a> -->
</div>